import React, { useCallback, useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { CountdownTimer } from "../components/countdown-timer";
import { Button, CardImg, CardHeader, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/CardStyle.css";
import { useNavigate } from 'react-router-dom';
import { useCart } from '../components/cartContext';
import { toast } from "react-toastify";
import { Form, FormGroup, Label, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useAuth0 } from "@auth0/auth0-react"; // Import the useAuth0 hook
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { VendorApplicationForm } from "./vendor-application";
import axios from 'axios';


const HomeTab = ({ token }) => {

  return (
    <div>
      <h2 className="">Home Tab</h2>
    </div>
  );
};

const VendorManagement = ({ token }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [businessPercentage, setBusinessPercentage] = useState(50);
  const [consignorPercentage, setConsignorPercentage] = useState(50);
  const [vendorId, setVendorId] = useState('');
  const [logo, setLogo] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [editVendor, setEditVendor] = useState(null); // Vendor being edited
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/get_all_vendor_info', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.success) {
          setVendors(data.data);
        } else {
          console.error('Failed to fetch vendors:', data.message);
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    fetchVendors();
  }, [getAccessTokenSilently]);

  const handleEditClick = (vendor) => {
    setEditVendor(vendor);
    setName(vendor.name);
    setAddress(vendor.address);
    setPhone(vendor.phone);
    setEmail(vendor.email);
    setBusinessPercentage(vendor.business_percentage);
    setConsignorPercentage(vendor.consignor_percentage);
    setVendorId(vendor.vendor_id);
    setLogo(null); // Clear the logo input
    setIsModalOpen(true); // Open the modal
  };

  const handleDelete = async (vendorId) => {
    if (!window.confirm('Are you sure you want to delete this vendor?')) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`https://api.bid605.com/vendors/delete`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ vendorId }),
      });

      const data = await response.json();
      if (data.success) {
        alert('Vendor deleted successfully');
        setVendors(vendors.filter((vendor) => vendor.id !== vendorId)); // Remove the deleted vendor from the list
      } else {
        alert('Failed to delete vendor');
      }
    } catch (error) {
      console.error('Error deleting vendor:', error);
      alert('An error occurred while deleting the vendor');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (editVendor) {
      formData.append('id', editVendor.id);
    }
    formData.append('name', name);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('businessPercentage', businessPercentage);
    formData.append('consignorPercentage', consignorPercentage);
    formData.append('vendorId', vendorId);
    if (logo) {
      formData.append('logo', logo);
    }

    console.log("Form Data:", Array.from(formData.entries())); // Log form data to verify

    try {
      const token = await getAccessTokenSilently();
      const url = editVendor ? `https://api.bid605.com/vendors/edit` : `https://api.bid605.com/vendors/add`;
      const response = await fetch(url, {
        method: editVendor ? 'PUT' : 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        alert(editVendor ? 'Vendor updated successfully' : 'Vendor added successfully');
        if (editVendor) {
          setVendors(vendors.map(v => (v.id === editVendor.id ? {
            ...v,
            name,
            address,
            phone,
            email,
            business_percentage: businessPercentage,
            consignor_percentage: consignorPercentage,
            vendor_id: vendorId,
            logo_url: logo ? URL.createObjectURL(logo) : v.logo_url
          } : v)));
        } else {
          setVendors([...vendors, {
            id: data.vendorId,
            name,
            address,
            phone,
            email,
            business_percentage: businessPercentage,
            consignor_percentage: consignorPercentage,
            vendor_id: vendorId,
            logo_url: logo ? URL.createObjectURL(logo) : ''
          }]);
        }
        setIsModalOpen(false);
        // Reset form fields
        setName('');
        setAddress('');
        setPhone('');
        setEmail('');
        setBusinessPercentage(50);
        setConsignorPercentage(50);
        setVendorId('');
        setLogo(null);
        setEditVendor(null); // Reset editVendor
      } else {
        alert('Failed to save vendor');
      }
    } catch (error) {
      console.error('Error saving vendor:', error);
      alert('An error occurred while saving the vendor');
    }
  };

  return (
    <div className="container">
      <h1 className="mb-4">Vendor Management</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="address">Address</Label>
          <Input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <Input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="businessPercentage">Business Percentage</Label>
          <Input type="number" id="businessPercentage" value={businessPercentage} onChange={(e) => setBusinessPercentage(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="consignorPercentage">Consignor Percentage</Label>
          <Input type="number" id="consignorPercentage" value={consignorPercentage} onChange={(e) => setConsignorPercentage(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="vendorId">Vendor&apos;s Clover Category ID</Label>
          <Input type="text" id="vendorId" value={vendorId} onChange={(e) => setVendorId(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="logo">Vendor Logo</Label>
          <Input type="file" id="logo" onChange={(e) => setLogo(e.target.files[0])} />
        </FormGroup>
        <Button color="primary" type="submit">
          {editVendor ? 'Update Vendor' : 'Add Vendor'}
        </Button>
      </Form>

      <Table bordered className="mb-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Business Percentage</th>
            <th>Consignor Percentage</th>
            <th>Vendor ID</th>
            <th>Logo</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vendors.map((vendor) => (
            <tr key={vendor.id}>
              <td>{vendor.id}</td>
              <td>{vendor.name}</td>
              <td>{vendor.address}</td>
              <td>{vendor.phone}</td>
              <td>{vendor.email}</td>
              <td>{vendor.business_percentage}%</td>
              <td>{vendor.consignor_percentage}%</td>
              <td>{vendor.vendor_id}</td>
              <td><img src={vendor.logo_url} alt="Logo" style={{ width: '50px', height: '50px' }} /></td>
              <td>
                <Button color="warning" onClick={() => handleEditClick(vendor)}>Edit</Button>
                {' '}
                <Button color="danger" onClick={() => handleDelete(vendor.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Editing Vendor */}
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Vendor</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
            </FormGroup>
            <FormGroup>
              <Label for="address">Address</Label>
              <Input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </FormGroup>
            <FormGroup>
              <Label for="businessPercentage">Business Percentage</Label>
              <Input type="number" id="businessPercentage" value={businessPercentage} onChange={(e) => setBusinessPercentage(e.target.value)} required />
            </FormGroup>
            <FormGroup>
              <Label for="consignorPercentage">Consignor Percentage</Label>
              <Input type="number" id="consignorPercentage" value={consignorPercentage} onChange={(e) => setConsignorPercentage(e.target.value)} required />
            </FormGroup>
            <FormGroup>
              <Label for="vendorId">Vendor&apos;s Clover Category ID</Label>
              <Input type="text" id="vendorId" value={vendorId} onChange={(e) => setVendorId(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="logo">Vendor Logo</Label>
              <Input type="file" id="logo" onChange={(e) => setLogo(e.target.files[0])} />
            </FormGroup>
            <Button color="primary" type="submit">Save Changes</Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const PromotionModal = ({ isOpen, toggle, vendors, selectedVendor, setSelectedVendor, onPromote }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Promote User</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="vendor">Select Vendor</Label>
          <Input type="select" id="vendor" value={selectedVendor} onChange={(e) => setSelectedVendor(e.target.value)}>
            <option value="">Select Vendor</option>
            {Array.isArray(vendors) && vendors.map((vendor) => (
              <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
            ))}
          </Input>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onPromote}>Promote</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

const AuctionItemManagement = ({ token }) => {
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/get_vendor_auction_items', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        console.log('ShopItemManagement Data:', data);
        setItems(data); // Ensure items is an array
        console.log('ShopItemManagement Items:', data.data);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, [getAccessTokenSilently]);

  const handleDelete = async (itemId) => {
    if (!window.confirm("Are you sure you want to delete this item?")) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/auction/items/delete', {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ itemId })
      });

      const data = await response.json();
      if (data.success) {
        alert('Item deleted successfully');
        setItems(items.filter(item => item.id !== itemId));
      } else {
        alert('Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('An error occurred while deleting the item');
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/auction/items/edit', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(selectedItem)
      });

      const data = await response.json();
      if (data.success) {
        alert('Item updated successfully');
        setItems(items.map(item => (item.id === selectedItem.id ? selectedItem : item)));
        setIsModalOpen(false);
      } else {
        alert('Failed to update item');
      }
    } catch (error) {
      console.error('Error updating item:', error);
      alert('An error occurred while updating the item');
    }
  };

  const filteredItems = items.filter(item => {
    return Object.keys(item).some(key =>
      item[key] != null && item[key].toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });


  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'center'
  };

  const tableStyle = {
    width: '80%',
    maxWidth: '1200px',
    minWidth: '800px',
    margin: '0 auto'
  };

  return (
    <div className="container">
      <h1 className="mb-4">Auction Item List</h1>
      <Input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        className="mb-4"
      />
      <div style={tableContainerStyle}>
      <Table bordered className="mb-4" style={tableStyle}>
        <thead>
          <tr>
            <th className="">ID</th>
            <th className="">Title</th>
            <th className="">Category</th>
            <th className="">Description</th>
            <th className="">Quantity</th>
            <th className="">Bidder</th>
            <th className="">Bid</th>
            <th className="">Email</th>
            <th className="">Paid</th>
            <th className="">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map(item => (
            <tr key={item.id}>
              <td className="">{item.id}</td>
              <td className="">{item.title}</td>
              <td className="">{item.category}</td>
              <td className="">{item.description}</td>
              <td className="">{item.quantity}</td>
              <td className="">{item.currentHighBidder}</td>
              <td className="">{item.currentBid}</td>
              <td className="">{item.currentHighBidderEmail}</td>
              <td className="">{item.paid}</td>
              <td>
                <Button color="warning" onClick={() => handleEdit(item)}>Edit</Button>
                <Button color="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>

      {selectedItem && (
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
          <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Item</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleEditSubmit}>
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  type="text"
                  id="title"
                  value={selectedItem.title}
                  onChange={(e) => setSelectedItem({ ...selectedItem, title: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="category">Category</Label>
                <Input
                  type="text"
                  id="category"
                  value={selectedItem.category}
                  onChange={(e) => setSelectedItem({ ...selectedItem, category: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  id="description"
                  value={selectedItem.description}
                  onChange={(e) => setSelectedItem({ ...selectedItem, description: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="number"
                  id="quantity"
                  value={selectedItem.quantity}
                  onChange={(e) => setSelectedItem({ ...selectedItem, quantity: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="price">Price</Label>
                <Input
                  type="number"
                  id="price"
                  value={selectedItem.price}
                  onChange={(e) => setSelectedItem({ ...selectedItem, price: e.target.value })}
                  required
                />
              </FormGroup>
              <Button color="primary" type="submit">Save</Button>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

const MemberManagement = ({ token }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUID, setSelectedUID] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  const toggleDropdown = (userId) => {
    setSelectedUserId(userId);
    setDropdownOpen(!dropdownOpen);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (!token) {
        return;
      }

      try {
        const response = await fetch('https://api.bid605.com/get_all_users', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    const fetchVendors = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/vendors', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setVendors(data);
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };
    fetchUsers();
    fetchVendors();
  }, [token]);

  const handleAction = async (action, userId, uid) => {
    if (action === 'setVendor') {
      setSelectedUserId(userId);
      toggleModal();
    }
    if (action === 'setAdmin') {
      setSelectedUserId(userId);
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/promote_admin_user', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: userId })
        });
  
        const data = await response.json();
        if (data.success) {
          alert('User promoted successfully');
          toggleModal();
        } else {
          alert('Failed to promote user');
        }
      } catch (error) {
        console.error('Error promoting user:', error);
        alert('An error occurred while promoting the user');
      }
    }
    if (action === 'setUser') {
      setSelectedUserId(userId);
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/promote_user_user', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: userId })
        });
  
        const data = await response.json();
        if (data.success) {
          alert('User promoted successfully');
        } else {
          alert('Failed to promote user');
        }
      } catch (error) {
        console.error('Error promoting user:', error);
        alert('An error occurred while promoting the user');
      }
    }
    if (action === 'ban') {
      setSelectedUserId(userId);
      setSelectedUID(uid);
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/ban_user', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: uid })
        });
  
        const data = await response.json();
        if (data.success) {
          alert('User banned permanently');
        } else {
          alert('Failed to ban user');
        }
      } catch (error) {
        console.error('Error promoting user:', error);
        alert('An error occurred while promoting the user');
      }
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users.filter(user =>
          (user.id && user.id.toString().toLowerCase().includes(value)) ||
          (user.name && user.name.toLowerCase().includes(value)) ||
          (user.email && user.email.toLowerCase().includes(value)) ||
          (user.uid && user.uid.toLowerCase().includes(value))
        )
      );
    }
  };

  const handlePromote = async () => {
    // Implement the logic to promote the user with the selected vendor
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/promote_vendor_user', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: selectedUserId, vendorId: selectedVendor })
      });

      const data = await response.json();
      if (data.success) {
        alert('User promoted successfully');
        toggleModal();
      } else {
        alert('Failed to promote user');
      }
    } catch (error) {
      console.error('Error promoting user:', error);
      alert('An error occurred while promoting the user');
    }
  };

  const dropdownMenuStyle = {
    position: 'absolute',
    zIndex: 1050,
    top: 0,
    left: 0
  };

  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'center'
  };

  const tableStyle = {
    width: '80%',
    maxWidth: '1200px',
    minWidth: '800px',
    margin: '0 auto'
  };
  return (
    <div>
      <h2 className="">Member Management</h2>
      <Input
        type="text"
        placeholder="Search by name or email"
        value={searchTerm}
        onChange={handleSearch}
        className="mb-3"
      />
      <div style={tableContainerStyle}>
        <Table bordered style={tableStyle}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>UID</th>
            <th>VendorID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => (
            <tr key={user.id}>
              <td className="">{user.id}</td>
              <td className="">{user.name}</td>
              <td className="">{user.email}</td>
              <td className="">{user.role}</td>
              <td className="">{user.uid}</td>
              <td className="">{user.vendor_id}</td>
              <td>
                <Dropdown
                 isOpen={dropdownOpen && selectedUserId === user.id}
                 toggle={() => toggleDropdown(user.id)}
                 container="body"
                >
                  <DropdownToggle caret className="">
                    Actions
                  </DropdownToggle>
                  <DropdownMenu style={dropdownMenuStyle} container="body">
                  <DropdownItem className="" onClick={() => handleAction('setUser', user.id, user.uid)}>Set User</DropdownItem>
                  <DropdownItem className="" onClick={() => handleAction('setVendor', user.id, user.uid)}>Set Vendor</DropdownItem>
                  <DropdownItem className="" onClick={() => handleAction('setAdmin', user.id, user.uid)}>Set Admin</DropdownItem>
                    <DropdownItem onClick={() => handleAction('ban', user.id, user.uid)}>Ban</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
      <PromotionModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        vendors={vendors}
        selectedVendor={selectedVendor}
        setSelectedVendor={setSelectedVendor}
        onPromote={handlePromote}
      />
    </div>
  );
};

const AddAuctionItem = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [startingPrice, setStartingPrice] = useState('');
  const [consignor, setConsignor] = useState('');
  const [vendors, setVendors] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.bid605.com/categories');
        const data = await response.json();
        if (data.success) {
          setCategories(data.data);
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchVendors = async () => {
      try {
        const response = await fetch('https://api.bid605.com/vendors');
        const data = await response.json();
        if (data.success) {
          setVendors(data.data);
        } else {
          console.error('Failed to fetch vendors');
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    fetchCategories();
    fetchVendors();
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', image);
    formData.append('category', category);
    formData.append('description', description);
    formData.append('startTime', startTime.toISOString());
    formData.append('endTime', endTime.toISOString());
    formData.append('startingPrice', startingPrice);
    formData.append('consignor', consignor);

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/items/add', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });
      const data = await response.json();
      if (data.success) {
        alert('Item added successfully');
      } else {
        alert('Failed to add item');
      }
    } catch (error) {
      console.error('Error adding item:', error);
      alert('An error occurred while adding the item');
    }
  };

  return (
    <div className="container">
      <h1 className="mb-4">Add Auction</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="image">Image</Label>
          <Input type="file" id="image" onChange={handleImageChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="category">Category</Label>
          <Input type="select" id="category" value={category} onChange={(e) => setCategory(e.target.value)} required >
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="description">Description</Label>
          <Input type="textarea" id="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="startTime">Auction Start Time</Label>
          <DatePicker selected={startTime} onChange={(date) => setStartTime(date)} showTimeSelect dateFormat="Pp" className="form-control" required />
        </FormGroup>
        <FormGroup>
          <Label for="endTime">Auction End Time</Label>
          <DatePicker selected={endTime} onChange={(date) => setEndTime(date)} showTimeSelect dateFormat="Pp" className="form-control" required />
        </FormGroup>
        <FormGroup>
          <Label for="startingPrice">Starting Price</Label>
          <Input type="number" id="startingPrice" value={startingPrice} onChange={(e) => setStartingPrice(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="consignor">Consignor</Label>
          <Input type="select" id="consignor" value={consignor} onChange={(e) => setConsignor(e.target.value)} required >
            <option value="">Select Consignor</option>
            {vendors.map(vendor => (
              <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
            ))}
          </Input>
        </FormGroup>
        <Button color="primary" type="submit">Add</Button>
      </Form>
    </div>
  );
};

const AddItem = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [variants, setVariants] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.bid605.com/categories');
        const data = await response.json();
        if (data.success) {
          setCategories(data.data);
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchVendors = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/vendors', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          setVendors(data.data);
        } else {
          console.error('Failed to fetch vendors');
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    fetchCategories();
    fetchVendors();
  }, [getAccessTokenSilently]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleAddVariant = () => {
    setVariants([...variants, { name: '', value: '', quantity: '', price: '' }]);
  };

  const handleVariantChange = (index, field, value) => {
    const newVariants = [...variants];
    newVariants[index][field] = value;
    setVariants(newVariants);
  };

  const handleClearVariants = () => {
    setVariants([]);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', image);
    formData.append('category', category);
    formData.append('description', description);
    formData.append('quantity', quantity);
    formData.append('price', price);
    formData.append('variants', JSON.stringify(variants));
    formData.append('vendorId', selectedVendor); // Add the selected vendor to the form data

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/items/shop/add', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        alert('Item added successfully');
        setTitle('');
        setImage(null);
        setCategory('');
        setDescription('');
        setQuantity('');
        setPrice('');
        setVariants([]);
        setSelectedVendor('');
      } else {
        alert('Failed to add item');
      }
    } catch (error) {
      console.error('Error adding item:', error);
      alert('An error occurred while adding the item');
    }
  };

  return (
    <div className="container">
      <h1 className="mb-4">Add Shop Item</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="image">Image</Label>
          <Input type="file" id="image" onChange={handleImageChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="category">Category</Label>
          <Input type="select" id="category" value={category} onChange={(e) => setCategory(e.target.value)} required >
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="vendor">Vendor</Label>
          <Input type="select" id="vendor" value={selectedVendor} onChange={(e) => setSelectedVendor(e.target.value)} required >
            <option value="">Select Vendor</option>
            {vendors.map(vendor => (
              <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="description">Description</Label>
          <Input type="textarea" id="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="quantity">Quantity</Label>
          <Input type="number" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="price">Price</Label>
          <Input type="number" id="price" value={price} onChange={(e) => setPrice(e.target.value)} required />
        </FormGroup>
        <h3>Variants</h3>
        <FormGroup>
          <Button onClick={handleAddVariant} color="secondary" className="mb-3">Add Variant</Button>
        </FormGroup>
        <FormGroup>
          <Button onClick={handleClearVariants} color="danger" className="mb-3 ml-3">Clear Variants</Button>
        </FormGroup>
        {variants.map((variant, index) => (
          <div key={index} className="mb-3">
            <FormGroup>
              <Label for={`variant-name-${index}`}>Variant Name</Label>
              <Input
                type="text"
                id={`variant-name-${index}`}
                value={variant.name}
                onChange={(e) => handleVariantChange(index, 'name', e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for={`variant-value-${index}`}>Variant Value</Label>
              <Input
                type="text"
                id={`variant-value-${index}`}
                value={variant.value}
                onChange={(e) => handleVariantChange(index, 'value', e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for={`variant-quantity-${index}`}>Variant Quantity</Label>
              <Input
                type="number"
                id={`variant-quantity-${index}`}
                value={variant.quantity}
                onChange={(e) => handleVariantChange(index, 'quantity', e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for={`variant-price-${index}`}>Variant Price</Label>
              <Input
                type="number"
                id={`variant-price-${index}`}
                value={variant.price}
                onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
                required
              />
            </FormGroup>
            {index < variants.length - 1 && <hr />} {/* Add a divider between variants */}
          </div>
        ))}
        <FormGroup>
          <Button color="primary" type="submit">Add Item</Button>
        </FormGroup>
      </Form>
    </div>
  );
};

const ShopItemList = ({ token }) => {
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/shop/items', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setItems(data.data);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, [getAccessTokenSilently]);

  const handleDelete = async (itemId) => {
    if (!window.confirm("Are you sure you want to delete this item?")) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/shop/items/delete', {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ itemId })
      });

      const data = await response.json();
      if (data.success) {
        alert('Item deleted successfully');
        setItems(items.filter(item => item.id !== itemId));
      } else {
        alert('Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('An error occurred while deleting the item');
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', selectedItem.id);
    formData.append('title', selectedItem.title);
    formData.append('category', selectedItem.category);
    formData.append('description', selectedItem.description);
    formData.append('quantity', selectedItem.quantity);
    formData.append('price', selectedItem.price);
    if (selectedItem.image) {
      formData.append('image', selectedItem.image);
    }

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/shop/items/edit', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        alert('Item updated successfully');
        setItems(items.map(item => (item.id === selectedItem.id ? selectedItem : item)));
        setIsModalOpen(false);
      } else {
        alert('Failed to update item');
      }
    } catch (error) {
      console.error('Error updating item:', error);
      alert('An error occurred while updating the item');
    }
  };

  const handleImageChange = (e) => {
    setSelectedItem({ ...selectedItem, image: e.target.files[0] });
  };

  return (
    <div className="container">
      <h1 className="mb-4">Shop Item List</h1>
      <Table bordered className="mb-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Category</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.title}</td>
              <td>{item.category}</td>
              <td>{item.description}</td>
              <td>{item.quantity}</td>
              <td>{item.price}</td>
              <td>
                <Button color="warning" onClick={() => handleEdit(item)}>Edit</Button>
                <Button color="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {selectedItem && (
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
          <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Item</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleEditSubmit}>
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  type="text"
                  id="title"
                  value={selectedItem.title}
                  onChange={(e) => setSelectedItem({ ...selectedItem, title: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="category">Category</Label>
                <Input
                  type="text"
                  id="category"
                  value={selectedItem.category}
                  onChange={(e) => setSelectedItem({ ...selectedItem, category: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  id="description"
                  value={selectedItem.description}
                  onChange={(e) => setSelectedItem({ ...selectedItem, description: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="number"
                  id="quantity"
                  value={selectedItem.quantity}
                  onChange={(e) => setSelectedItem({ ...selectedItem, quantity: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="price">Price</Label>
                <Input
                  type="number"
                  id="price"
                  value={selectedItem.price}
                  onChange={(e) => setSelectedItem({ ...selectedItem, price: e.target.value })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="image">Image</Label>
                <Input
                  type="file"
                  id="image"
                  onChange={handleImageChange}
                />
              </FormGroup>
              <Button color="primary" type="submit">Save</Button>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

const VendorApplications = () => {
  const [applications, setApplications] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/admin/vendor_applications', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          setApplications(data.data);
        } else {
          console.error('Failed to fetch applications');
        }
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };

    fetchApplications();
  }, [getAccessTokenSilently]);

  return (
    <div className="container">
      <h1 className="mb-4">Vendor Applications</h1>
      <Table bordered>
        <thead>
          <tr>
            <th>ID</th>
            <th>Business Name</th>
            <th>Item Type</th>
            <th>Contact Phone</th>
            <th>Contact Email</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>ZIP</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Application Date</th>
          </tr>
        </thead>
        <tbody>
          {applications.map(app => (
            <tr key={app.id}>
              <td>{app.id}</td>
              <td>{app.business_name}</td>
              <td>{app.item_type}</td>
              <td>{app.contact_phone}</td>
              <td>{app.contact_email}</td>
              <td>{app.address}</td>
              <td>{app.city}</td>
              <td>{app.state}</td>
              <td>{app.zip}</td>
              <td>{app.first_name}</td>
              <td>{app.last_name}</td>
              <td>{new Date(app.created_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const RequestLogs = ({ token }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    userId: '',
    method: '',
    search: '',
  });
  const [showHeaders, setShowHeaders] = useState(false);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get('https://api.bid605.com/api/request-logs', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
          },
        });
        setLogs(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchLogs();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
  };

  const filteredLogs = logs.filter((log) => {
    return (
      (filter.userId === '' || log.user_id === filter.userId) &&
      (filter.method === '' || log.method === filter.method) &&
      (filter.search === '' || log.url.includes(filter.search) || log.ip.includes(filter.search))
    );
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching logs: {error.message}</p>;

  return (
    <div style={{ margin: '20px' }}>
      <h2 style={{ marginBottom: '20px' }}>Request Logs</h2>
      <div style={{ marginBottom: '20px' }}>
        <label style={{ marginRight: '15px' }}>
          User ID:
          <input
            type="text"
            name="userId"
            value={filter.userId}
            onChange={handleFilterChange}
            placeholder="Filter by User ID"
          />
        </label>
        <label style={{ marginRight: '15px' }}>
          Method:
          <select name="method" value={filter.method} onChange={handleFilterChange}>
            <option value="">All</option>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
            <option value="PATCH">PATCH</option>
          </select>
        </label>
        <label style={{ marginRight: '15px' }}>
          Search:
          <input
            type="text"
            name="search"
            value={filter.search}
            onChange={handleFilterChange}
            placeholder="Search by URL or IP"
          />
        </label>
        <button onClick={() => setShowHeaders(!showHeaders)} style={{ marginLeft: '15px' }}>
          {showHeaders ? 'Hide Headers' : 'Show Headers'}
        </button>
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>ID</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>User ID</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>IP</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Method</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>URL</th>
            {showHeaders && (
              <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Headers</th>
            )}
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Body</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Authenticated</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {filteredLogs.map((log) => (
            <tr key={log.id}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.id}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.user_id || 'Unauthenticated'}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.ip}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.method}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.url}</td>
              {showHeaders && (
                <td style={{ border: '1px solid #ddd', padding: '8px' }}><pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(log.headers, null, 2)}</pre></td>
              )}
              <td style={{ border: '1px solid #ddd', padding: '8px' }}><pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{log.body ? JSON.stringify(log.body, null, 2) : 'N/A'}</pre></td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.authenticated ? 'Yes' : 'No'}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{new Date(log.timestamp).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const OrderManagement = ({ token }) => {
  const [soldItems, setSoldItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchSoldItems = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/admin/sold_items', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          setSoldItems(data.data);
        } else {
          toast.error('Failed to fetch sold items');
        }
      } catch (error) {
        console.error('Error fetching sold items:', error);
        toast.error('Error fetching sold items');
      }
    };

    fetchSoldItems();
  }, [getAccessTokenSilently]);

  const handleUpdateStatus = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/admin/sold_items/update_status', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ soldItemId: selectedItem.id, status })
      });
      const data = await response.json();
      if (data.success) {
        toast.success('Order status updated successfully');
        setSoldItems(soldItems.map(item => item.id === selectedItem.id ? { ...item, status } : item));
        setIsModalOpen(false);
      } else {
        toast.error('Failed to update order status');
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error('Error updating order status');
    }
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setStatus(item.status);
    setIsModalOpen(true);
  };

  return (
    <div className="container">
      <h1 className="mb-4">Order Management</h1>
      <Table bordered className="mb-4">
        <thead>
          <tr>
            <th className="text-white">ID</th>
            <th className="text-white">Item ID</th>
            <th className="text-white">Item Name</th>
            <th className="text-white">Customer</th>
            <th className="text-white">Quantity</th>
            <th className="text-white">TXID</th>
            <th className="text-white">Status</th>
            <th className="text-white">Actions</th>
          </tr>
        </thead>
        <tbody>
        {soldItems.map(item => {
          const buyerInfo = item.buyer_info || {};
          const itemName = item.regular_item_name || item.auction_item_name || 'Unknown';

          return (
            <tr key={item.id}>
              <td className="text-black">{item.id}</td>
              <td className="text-black">{item.item_id}</td>
              <td className="text-black">{itemName}</td>
              <td className="text-black">
                {buyerInfo.name} <br />
                {buyerInfo.email} <br />
                {buyerInfo.address} <br />
                {buyerInfo.city} <br />
                {buyerInfo.state} <br />
                {buyerInfo.zip} <br />
              </td>
              <td className="text-black">{item.quantity}</td>
              <td className="text-black">{item.tx_id}</td>
              <td className="text-black">{item.status}</td>
              <td>
                <Button color="warning" onClick={() => openModal(item)}>Update Status</Button>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>

      {selectedItem && (
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
          <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Update Order Status</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleUpdateStatus}>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                >
                  <option value="Pending">Pending</option>
                  <option value="Processed">Processed</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                </Input>
              </FormGroup>
              <Button color="primary" type="submit">Update</Button>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export const Administration = ({token}) => {
  const [activeKey, setActiveKey] = useState("OrderTab");

  const handleSelect = (key) => {
    setActiveKey(key);
  };
  useEffect(() => {

  }, []);

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Administration</h1>
        <div className="content__body">
          <Col>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" activeKey={activeKey} onSelect={handleSelect} >
            <Tab eventKey="OrderTab" title="Orders"><OrderManagement token={token}/></Tab>
            <Tab eventKey="AddAuctionItem" title="Add Auction"><AddAuctionItem token={token}/></Tab>
            <Tab eventKey="AuctionItemManagement" title="Manage Auctions Items"><AuctionItemManagement token={token}/></Tab>
            <Tab eventKey="AddItem" title="Add Item"><AddItem token={token}/></Tab>
            <Tab eventKey="ShopItemList" title="Shop Item List"><ShopItemList token={token}/></Tab>
            <Tab eventKey="AddVendor" title="Add Vendor"><VendorManagement token={token}/></Tab>
            <Tab eventKey="ManageMembers" title="Members"><MemberManagement token={token}/></Tab>
            <Tab eventKey="VendorApplications" title="Vendor Apps"><VendorApplications token={token}/></Tab>
            <Tab eventKey="ReqLog" title="Req Log"><RequestLogs token={token}/></Tab>
            </Tabs>
          </Col>
        </div>
      </div>
    </PageLayout>
  );
};
