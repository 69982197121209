import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { Table, Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { CodeSnippet } from "../components/code-snippet";
import { PageLoader } from "../components/page-loader";
import { toast } from 'react-toastify';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const Settings = ({ child }) => {
  console.log("child", child);

  return (
    <div>
      <h2>Settings</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Col 1</th>
            <th>Col 2</th>
            <th>Col 3</th>
            <th>Col 4</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </Table>
    </div>
  );
};

const VendorOrderManagement = ({ token }) => {
  const [soldItems, setSoldItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchSoldItems = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/user/orders', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          setSoldItems(data.data);
        } else {
          toast.error('Failed to fetch sold items');
        }
      } catch (error) {
        console.error('Error fetching sold items:', error);
        toast.error('Error fetching sold items');
      }
    };

    fetchSoldItems();
  }, [getAccessTokenSilently]);

  const handleUpdateStatus = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/user/orders/update_status', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ soldItemId: selectedItem.id, status })
      });
      const data = await response.json();
      if (data.success) {
        toast.success('Order status updated successfully');
        setSoldItems(soldItems.map(item => item.id === selectedItem.id ? { ...item, status } : item));
        setIsModalOpen(false);
      } else {
        toast.error('Failed to update order status');
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error('Error updating order status');
    }
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setStatus(item.status);
    setIsModalOpen(true);
  };

  return (
    <div className="container">
      <h1 className="mb-4">Orders</h1>
      <Table bordered className="mb-4">
        <thead>
          <tr>
            <th className="text-white">ID</th>
            <th className="text-white">Item ID</th>
            <th className="text-white">Customer</th>
            <th className="text-white">Quantity</th>
            <th className="text-white">TXID</th>
            <th className="text-white">Status</th>
            <th className="text-white">Actions</th>
          </tr>
        </thead>
        <tbody>
        {soldItems.map(item => {
          const buyerInfo = item.buyer_info || {};

          return (
            <tr key={item.id}>
              <td className="text-black">{item.id}</td>
              <td className="text-black">{item.item_id}</td>
              <td className="text-black">
                {buyerInfo.name} <br />
                {buyerInfo.email} <br />
                {buyerInfo.address} <br />
                {buyerInfo.city} <br />
                {buyerInfo.state} <br />
                {buyerInfo.zip} <br />
              </td>
              <td className="text-black">{item.quantity}</td>
              <td className="text-black">{item.tx_id}</td>
              <td className="text-black">{item.status}</td>
              <td>
                <Button color="warning" onClick={() => openModal(item)}>Update Status</Button>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>

      {selectedItem && (
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
          <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Update Order Status</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleUpdateStatus}>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                >
                  <option value="Cancel">Cancel</option>
                </Input>
              </FormGroup>
              <Button color="primary" type="submit">Update</Button>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

const Profile = ({ profile, handleChange, submitProfile }) =>  {
  return (
    <div>
      <h2>Profile Settings</h2>
      <Form>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="firstName">First name</Label>
              <Input type="text" name="firstName" id="firstName" value={profile.firstName} onChange={handleChange} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="lastName">Last name</Label>
              <Input type="text" name="lastName" id="lastName" value={profile.lastName} onChange={handleChange} />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input type="email" name="email" id="email" value={profile.email} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="username">Username</Label>
          <Input type="text" name="username" id="username" value={profile.username} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="address">Address</Label>
          <Input type="text" name="address" id="address" value={profile.address} onChange={handleChange} />
        </FormGroup>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="city">City</Label>
              <Input type="text" name="city" id="city" value={profile.city} onChange={handleChange} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="state">State</Label>
              <Input type="text" name="state" id="state" value={profile.state} onChange={handleChange} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="zip">Zip</Label>
              <Input type="text" name="zip" id="zip" value={profile.zip} onChange={handleChange} />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <div>
            <Input type="checkbox" id="outbid_notification" name="outbid_notification" checked={profile.outbid_notification} onChange={handleChange} />
            <Label for="outbid_notification"> - Notify when I am Outbid </Label>
          </div>
          <div>
            <Input type="checkbox" id="win_notification" name="win_notification" checked={profile.win_notification} onChange={handleChange} />
            <Label for="win_notification"> - Notify when I Win </Label>
          </div>
        </FormGroup>
        <Button color="primary" onClick={submitProfile}>
          Save
        </Button>
      </Form>
    </div>
  );
};

export const ProfilePage =  ({ token, profileData }) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [activeKey, setActiveKey] = useState("profile");
  const [isExploding, setIsExploding] = useState(false);
  const { width, height } = useWindowSize();
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    outbid_notification: false,
    win_notification: false,
  });
  const [showDebug, setShowDebug] = useState(false);

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log("Change", name, value, type, checked);
    setProfile(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const submitProfile = async () => {
    console.log("Attempting to submit profile data:", profile);
    try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/update`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(profile)
        });
        const responseData = await response.json();
        console.log("Server response:", responseData);
        toast.success("Profile Updated!");

        setIsExploding(true);
        setTimeout(() => {
          setIsExploding(false);
        }, 5000);

    } catch (error) {
        console.error('Error submitting profile:', error);
    }
  };

  useEffect(() => {
    if (profileData) {
      setProfile({
        firstName: profileData.firstname || '',
        lastName: profileData.lastname || '',
        email: profileData.email || '',
        username: profileData.username || '',
        address: profileData.address || '',
        city: profileData.city || '',
        state: profileData.state || '',
        zip: profileData.zip || '',
        outbid_notification: profileData.outbid_notification || false,
        win_notification: profileData.win_notification || false,
      });
    }
  }, [profileData]);

  if (!user || !profileData || !token) {
    return <PageLoader />;
  }

  console.log("profileData ", profileData);
  console.log("profileData outbid_notification", profileData.outbid_notification);
  console.log("profileData win_notification", profileData.win_notification);

  return (
    <PageLayout>
      {isExploding && <Confetti width={width} height={height} />}
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Profile</h1>
        <div className="content__body">
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" activeKey={activeKey} onSelect={handleSelect} >
            <Tab eventKey="profile" title="Profile">
              <Profile profile={profile} handleChange={handleChange} submitProfile={submitProfile} />
            </Tab>
            <Tab eventKey="orders" title="Orders">
              <VendorOrderManagement profile={profile} handleChange={handleChange} submitProfile={submitProfile} />
            </Tab>

          </Tabs>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={showDebug} onChange={() => setShowDebug(!showDebug)} />{' '}
              Debug
            </Label>
          </FormGroup>
          {showDebug && (
            <>
              <CodeSnippet title="user" code={JSON.stringify(user, null, 2)} />
              <CodeSnippet title="profileData" code={JSON.stringify(profileData, null, 2)} />
              <CodeSnippet title="token" code={JSON.stringify(token, null, 2)} />
            </>
          )}
        </div>
      </div>
    </PageLayout>
  );
};
