import React, { useState, useEffect } from 'react';

// Style objects for countdown timer
const countdownTimerStyles = {
  fontWeight: "bold",
  color: "#28a745", // Normal color green
};

const nearingEndStyles = {
  color: "#dc3545", // Color for when the end is near, red
};

export const CountdownTimer = ({ deadline }) => {
  const calculateTimeLeft = () => {
    const difference = deadline - new Date().getTime();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const isNearingEnd = timeLeft.days === 0; // Condition to check if the deadline is near

  return (
    <div>
      {Object.keys(timeLeft).length > 0 ? (
        <span style={isNearingEnd ? { ...countdownTimerStyles, ...nearingEndStyles } : countdownTimerStyles}>
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
        </span>
      ) : (
        <span style={countdownTimerStyles}>Time up!</span>
      )}
    </div>
  );
};
