import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from 'react';
import { MobileNavBarTab } from "./mobile-nav-bar-tab";
import { useTokenProvider  } from '../../tokenProvider'; // Adjust the import path as necessary

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated } = useAuth0();
  const token = useTokenProvider ();
  const [userRole, setUserRole] = useState(null); // Simplified to a single state

  useEffect(() => {
    if (!token) return;
    const getUserProfile = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/get_user_profile/`, {
              method: 'GET', // Correct method for fetching data
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
              },
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          console.log('User Profile:', data)
          console.log('User Profile:', data.role)
          setUserRole(data.role);  
      } catch (error) {
          console.error('Error fetching session details:', error);
      }
    };

    getUserProfile();
  }, [token]);

  return (
    <div className="mobile-nav-bar__tabs">
      <MobileNavBarTab path="/vendor-application" label="Vendor Application" handleClick={handleClick} />
      {/*
        <MobileNavBarTab path="/previous" label="Previous Auctions" handleClick={handleClick} />
      */}

      {!isAuthenticated && (
        <>
          <MobileNavBarTab path="/auctions" label="Auctions" handleClick={handleClick} />
          <MobileNavBarTab path="/shop" label="Shop" handleClick={handleClick} />
        </>
      )}
      {isAuthenticated && (
        <>
          <MobileNavBarTab path="/auth-auctions" label="Auctions" handleClick={handleClick} />
          <MobileNavBarTab path="/auth-shop" label="Shop" handleClick={handleClick} />
          <MobileNavBarTab path="/winnings" label="Winnings" handleClick={handleClick} />
          <MobileNavBarTab path="/profile" label="Profile" handleClick={handleClick} />
          <MobileNavBarTab path="/cart" label="Cart" handleClick={handleClick} />
          {(userRole === 'admin') && <MobileNavBarTab path="/administration" label="Admin" />}
          {(userRole === 'admin' || userRole === 'vendor') && <MobileNavBarTab path="/vendor-management" label="Vendors" />}
        </>
      )}
    </div>
  );
};
