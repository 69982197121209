import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { CountdownTimer } from "../components/countdown-timer";
import { CardImg, CardHeader, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/CardStyle.css";


const ItemCard = ({ title, deadline, currentBid, currentBidder, image }) => {

  
  return (
    
    <Card className="lottery-card my-3" color="secondary" inverse >
      <CardHeader className=''>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div>{title}</div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardImg 
        alt="Auction Item Image" 
        src={`https://bid605.com${image}`}
        style={{ width: "100%", height: "auto", objectFit: "cover" }}
        onError={(e) => e.target.src = `${image}`}
      />
      <CardBody>
        <CardTitle tag="h5">
          <div></div>
        </CardTitle>
        <CardSubtitle className="mb-2" tag="h1" style={{color:'gold'}}>
          <div className="center">Current Bid: {currentBid}</div>
          <div className="center">{currentBidder}</div>
        </CardSubtitle>
        <div className='center'>
          <CountdownTimer deadline={deadline} />
        </div>
      </CardBody>
    </Card>
  );
};

export const PublicAuctions = () => {
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState(['All']);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  
  useEffect(() => {
    document.title = "Auctions | Auctions";
    const fetchItems = async () => {
      try {
        const response = await fetch('https://api.bid605.com/items/active');
        const data = await response.json(); // Parse JSON from the response
        if (data.success && data.data) {
          const fetchedItems = data.data.map(item => ({
            ...item,
            deadline: new Date(parseInt(item.endTime)).getTime()
          }));
          const uniqueCategories = new Set(fetchedItems.map(item => item.category));
          setCategories(['All', ...Array.from(uniqueCategories)]);
          setItems(fetchedItems);

        } else {
          throw new Error('Failed to load items');
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, []);

  useEffect(() => {
    const ws = new WebSocket('wss://bid605.com/websocket');
    console.log('Connecting to WebSocket');
    ws.onopen = () => {
        console.log('Connected to WebSocket');
    };

    ws.onmessage = (event) => {
        const newData = JSON.parse(event.data);
        console.log("WebSocket Data:", newData);

        setItems(prevItems => {
            const itemsMap = new Map(prevItems.map(i => [i.id, i])); // Create a map of existing items for quick lookup

            newData.forEach(item => {
                const newItem = {
                    id: item[0],
                    title: item[1],
                    image: item[2],
                    category: item[3],
                    currentBid: item[11],
                    currentBidder: item[9],
                    deadline: new Date(parseInt(item[6])).getTime() // Assuming endTime is the deadline
                };

                itemsMap.set(newItem.id, {...itemsMap.get(newItem.id), ...newItem}); // Update or add new item, merging with existing data
            });

            return Array.from(itemsMap.values()); // Convert back to array
        });
    };

    ws.onerror = (error) => {
        console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
        console.log('WebSocket connection closed');
    };

    return () => {
        ws.close(); // Clean up WebSocket connection when component unmounts
    };
}, []);

  


  return (
    <PageLayout>
      
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Auctions</h1>
        
        <div className="content__body">
          <Col>
          <select className="form-group" onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
            {categories.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>

            <Row sm="4">
            {items.filter(item => selectedCategory === 'All' || item.category === selectedCategory)
              .map(item => (
                <ItemCard
                  key={item.id}
                  title={item.title}
                  currentBid={item.currentBid}
                  currentBidder={item.currentBidder}
                  deadline={item.deadline}
                  image={item.image}
                  category={item.category}
                />
            ))}
            </Row>
          </Col>
        </div>
      </div>
    </PageLayout>
  );
};
