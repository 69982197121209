import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { CountdownTimer } from "../components/countdown-timer";
import { Button, Input, CardImg, CardHeader, Card, CardBody, CardTitle, CardSubtitle, Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import { Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/CardStyle.css";
import { useTokenProvider  } from '../components/tokenProvider'; // Adjust the import path as necessary

import Confetti from 'react-confetti'
import { toast } from 'react-toastify';
import ConfettiExplosion from 'react-confetti-explosion';

const BidHistoryModal = ({ isOpen, toggle, itemId, token }) => {
  const [bidHistory, setBidHistory] = useState([]);

  useEffect(() => {
    const fetchBidHistory = async () => {
      try {
        const response = await fetch(`https://api.bid605.com/items/${itemId}/bids`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const result = await response.json();
        if (result.success) {
          setBidHistory(result.data);
          console.log("Bid History", result.data);
        } else {
          toast.error(result.message || "Failed to fetch bid history.");
        }
      } catch (error) {
        console.error('Fetch Bid History Error:', error);
        toast.error("An error occurred while fetching the bid history.");
      }
    };

    if (isOpen) fetchBidHistory();
  }, [isOpen, itemId, token]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Bid History for Item ID: {itemId}</ModalHeader>
      <ModalBody>
        {bidHistory.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Bid</th>
                <th>User</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {bidHistory.map((bid) => (
                <tr key={bid.id}>
                  <td style={{fontSize:"1.5em", color:"white"}}>{bid.id}</td>
                  <td style={{fontSize:"1.5em", color:"white"}}>{bid.bid}</td>
                  <td style={{fontSize:"1.5em", color:"white"}}>{bid.user_name}</td>
                  <td style={{fontSize: "1em", color: "white" }}>
                    {new Date(parseInt(bid.timestamp) * 1000).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No bids found for this item.</p>
        )}
      </ModalBody>
    </Modal>
  );
};


const ItemCard = ({ isExploding, setIsExploding, title, deadline, currentBid, currentBidder, image, item, token }) => {
  const [bidAmount, setBidAmount] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const submitBid = async (itemId) => {
    console.log("submitBid", itemId, bidAmount);
    try {
      const response = await fetch(`https://api.bid605.com/items/${itemId}/bid`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ bid: parseFloat(bidAmount) })
      });

      const result = await response.json();
      if (result.success) {
        toast.success("Bid Submitted Successfully!");
        setIsExploding(true);
        setTimeout(() => setIsExploding(false), 5000);
      } else {
        toast.error(result.message || "Failed to submit bid.");
        toast.error(result.error || "Failed to submit bid.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting your bid.");
      console.error("Submit Bid Error:", error);
    }
  };
  
  const handleBidChange = (e) => {
    console.log("handleBidChange", e.target.value);
    setBidAmount(e.target.value);
  };
  
  return (
    <Card className="lottery-card my-3" color="secondary" inverse >
      <CardHeader className=''>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div>{title}</div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardImg 
        alt="Auction Item Image" 
        src={`https://bid605.com${image}`}
        style={{ width: "100%", height: "auto", objectFit: "cover" }}
        onError={(e) => e.target.src = `${image}`}
      />
      <CardBody>
        <CardTitle tag="h5">
          <div></div>
        </CardTitle>
        <CardSubtitle className="mb-3" tag="h1" style={{color:'gold'}}>
          <div className="center mb-2" style={{color:'green'}}>
            Current Bid: {currentBid}
          </div>
          <div className="center mb-2">
            {item.currentHighBidder}
          </div>
        </CardSubtitle>
        <div className='center mb-2'>
          <CountdownTimer deadline={deadline} />
        </div>
        <div className='form-control center mb-3'>
          <Input type="text" name="bid" id="bid" placeholder="Bid" onChange={handleBidChange} />
        </div>
        {isExploding && <ConfettiExplosion force="2" duration="5000"/>}
        <div className='center '>
            <Button color="primary" onClick={() => submitBid(item.id)}>
              Place Bid
            </Button>
          </div>
          <div className='center '>
            <Button color="secondary" className="ml-2" onClick={toggleModal}>
              Show History
            </Button>
        </div>
        <BidHistoryModal isOpen={isModalOpen} toggle={toggleModal} itemId={item.id} token={token} />
      </CardBody>

    </Card>
  );
};

export const AuthAuctions = () => {
  const [items, setItems] = useState([]);
  //const { width, height } = useWindowSize()
  const [isExploding, setIsExploding] = useState(false);
  const [confettiDimensions, setConfettiDimensions] = useState({ width: 0, height: 0 });
  const token = useTokenProvider ();
  const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
  const [isWebSocketError, setIsWebSocketError] = useState(false);
  const maxRetries = 5;
  const retryDelay = 3000; // 3 seconds
  let retryCount = 0;

  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState(['All']);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    const updateConfettiDimensions = () => {
      setConfettiDimensions({
        width: document.documentElement.scrollWidth,
        height: document.documentElement.scrollHeight
      });
    };

    updateConfettiDimensions();
    window.addEventListener('resize', updateConfettiDimensions);
    window.addEventListener('scroll', updateConfettiDimensions);

    return () => {
      window.removeEventListener('resize', updateConfettiDimensions);
      window.removeEventListener('scroll', updateConfettiDimensions);
    };
  }, []);

  useEffect(() => {
    document.title = "Auctions | Auctions";
    const fetchItems = async () => {
      try {
        const response = await fetch('https://api.bid605.com/items/active');
        const data = await response.json(); // Parse JSON from the response
        if (data.success && data.data) {
          const fetchedItems = data.data.map(item => ({
            id: item[0],
            title: item[1],
            image: item[2],
            category: item[3],
            description: item[4],
            startTime: item[5],
            endTime: new Date(parseInt(item[6])).getTime(),
            deadline: new Date(parseInt(item[6])).getTime(),
            startPrice: item[7],
            currentHighBidderEmail: item[8],
            currentHighBidder: item[9],
            uid: item[10],
            currentBid: item[11],
            enabled: item[12],
            paid: item[13],
            paid_id: item[14],
            tx_obj: item[15],
            notified: item[16],
            addedByUser: item[17],
            consignorid: item[18],
            clover_itemid: item[19] // Assuming endTime is the deadline
          }));
          setItems(fetchedItems);
          const uniqueCategories = new Set(fetchedItems.map(item => item.category));
          setCategories(['All', ...Array.from(uniqueCategories)]);
        } else {
          throw new Error('Failed to load items');
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, []);

  const connectWebSocket = () => {
    const ws = new WebSocket('wss://bid605.com/websocket');
    ws.onopen = () => {
      console.log('Connected to WebSocket');
      setIsWebSocketConnected(true);
      setIsWebSocketError(false);
      retryCount = 0;
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const mappedItems = data.map((item, index) => ({
        id: item[0],
        title: item[1],
        image: item[2],
        category: item[3],
        description: item[4],
        startTime: item[5],
        endTime: new Date(parseInt(item[6])).getTime(),
        deadline: new Date(parseInt(item[6])).getTime(),
        startPrice: item[7],
        currentHighBidderEmail: item[8],
        currentHighBidder: item[9],
        uid: item[10],
        currentBid: item[11],
        enabled: item[12],
        paid: item[13],
        paid_id: item[14],
        tx_obj: item[15],
        notified: item[16],
        addedByUser: item[17],
        consignorid: item[18],
        clover_itemid: item[19], // Assuming endTime is the deadline
        key: `item-${index}`
      }));
      setItems(mappedItems);
      const uniqueCategories = new Set(mappedItems.map(item => item.category));
      setCategories(['All', ...Array.from(uniqueCategories)]);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setIsWebSocketConnected(false);
      setIsWebSocketError(true);
      retryWebSocketConnection();
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
      setIsWebSocketConnected(false);
      setIsWebSocketError(true);
      retryWebSocketConnection();
    };
  };

  const retryWebSocketConnection = () => {
    if (retryCount < maxRetries) {
      retryCount++;
      setTimeout(() => {
        console.log(`Retrying WebSocket connection (${retryCount}/${maxRetries})...`);
        connectWebSocket();
      }, retryDelay);
    } else {
      console.error('Max WebSocket retry attempts reached.');
    }
  };

  useEffect(() => {
    if (token) {
      console.log('Connecting to WebSocket');
      connectWebSocket();
    }
  }, [token]);


  return (
    <PageLayout>
      {/*isExploding && <Confetti width={confettiDimensions.width} height={confettiDimensions.height} />*/}
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Auctions</h1>
        <div className="content__body">
          {isWebSocketConnected ? (
              <Col>
              <select className="form-group" onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
                {categories.map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
              </select>

                <Row sm="4">
                {items.filter(item => selectedCategory === 'All' || item.category === selectedCategory).map(item => (
                    <ItemCard
                      key={item.id}
                      title={item.title}
                      currentBid={item.currentBid}
                      currentBidder={item.currentHighBidder}
                      deadline={item.deadline}
                      image={item.image}
                      item={item}
                      isExploding={isExploding}
                      setIsExploding={setIsExploding}
                      token={token}
                    />
                  ))}
                </Row>
              </Col>
            ) : (
              <div className="loading-message">
                <h2>Connecting to the auction server...</h2>
              </div>
            )}
        </div>
      </div>
    </PageLayout>
  );
};
