import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from "../components/page-layout";
import { Row, Col, Button, CardText, CardImg, CardHeader, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useTokenProvider } from '../components/tokenProvider';
import { useCart } from '../components/cartContext';
import { toast } from "react-toastify";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/CardStyle.css";

export const ViewItem = () => {
  const { itemId } = useParams();
  const [item, setItem] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const { addItem } = useCart();

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const response = await fetch(`https://api.bid605.com/shop-items/${itemId}`);
        const data = await response.json();
        if (data.success) {
          setItem(data.item);
        } else {
          throw new Error('Failed to fetch item details');
        }
      } catch (error) {
        console.error('Error fetching item details:', error);
      }
    };

    fetchItemDetails();
  }, [itemId]);

  const handleAddToCart = useCallback(() => {
    if (!selectedVariant) {
      // Add base item to cart
      addItem({
        id: item.id,
        name: item.title,
        price: item.price,
        itemid: item.clover_itemid,
        maxQuantity: item.quantity,
        itemType: "shop",
        vendor_id: item.consignorid,
      });
      toast.success("Base item added to cart");
    } else {
      // Add selected variant to cart
      addItem({
        id: selectedVariant.id,
        name: `${item.title} - ${selectedVariant.name}: ${selectedVariant.value}`,
        price: selectedVariant.price,
        itemid: selectedVariant.clover_itemid,
        maxQuantity: selectedVariant.quantity,
        itemType: "shop",
      });
      toast.success("Variant added to cart");
    }
  }, [addItem, item, selectedVariant]);

  if (!item) return <PageLayout><div>Loading...</div></PageLayout>;

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Auth Shop</h1>
        <div className="content__body">
          <Col>
            <Row sm="12">
              <div>
                <h1>{item.title}</h1>
              </div>
              <Card className="my-3 row row-cols-sm-12" color="secondary" inverse >
                <CardTitle tag="h5" >
                  <div>{item.title}</div>
                </CardTitle>
                <CardBody className="d-flex justify-content-center">
                  <CardImg 
                    className="center"
                    alt="Card image cap" 
                    src={`${item.image}`}
                    style={{ width: "50%", height: "auto", objectFit: "cover" }}
                    onError={(e) => e.target.src = 'path_to_fallback_image.jpg'}
                  />
                </CardBody>
                <CardBody>
                  <CardSubtitle className="mb-12" tag="h1" style={{color:'gold'}}>
                    <div className="center">${item.price}</div>
                  </CardSubtitle>
                  <CardText>
                    <p style={{fontSize:'2em'}}>{item.description}</p>
                  </CardText>
                  <div>
                    <h3>Variants</h3>
                    <Button style={{size:"10px", xcolor:'white', background: 'blue'}} onClick={() => setSelectedVariant(null)} color={!selectedVariant ? 'primary' : 'secondary'} >
                      Default - ${item.price}
                    </Button>
                    {item.variants && item.variants.length > 0 ? (
                      <div>
                        {item.variants.map(variant => (
                          <Button
                            key={variant.id}
                            onClick={() => setSelectedVariant(variant)}
                            color={selectedVariant === variant ? 'primary' : 'secondary'}
                          >
                            {variant.name}: {variant.value} - ${variant.price}
                          </Button>
                        ))}
                      </div>
                    ) : (
                      <p>No variants available for this item.</p>
                    )}
                  </div>
                  <Button onClick={handleAddToCart} color="primary">Add to Cart</Button>
                </CardBody>
              </Card>
            </Row>
          </Col>
        </div>
      </div>
    </PageLayout>
  );
};
