import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { CardImg, CardHeader, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/CardStyle.css";

const ItemCard = ({ title, price, image }) => {
  return (
    <Card className="lottery-card my-3" color="secondary" inverse >
      <CardHeader className=''>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div>{title}</div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardImg 
        alt="Card image cap" 
        src={`${image}`}
        style={{ width: "100%", height: "auto", objectFit: "cover" }}
        onError={(e) => e.target.src = 'path_to_fallback_image.jpg'}
      />
      <CardBody>
        <CardTitle tag="h5">
          <div></div>
        </CardTitle>
        <CardSubtitle className="mb-2" tag="h1" style={{color:'gold'}}>
          <div className="center">${price}</div>
        </CardSubtitle>
      </CardBody>
    </Card>
  );
};

export const PublicShop = () => {
  const [items, setItems] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState(['All']);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  useEffect(() => {
    document.title = "Auctions | Auctions";
    const fetchItems = async () => {
      try {
        const response = await fetch('https://api.bid605.com/items/shop/active');
        const data = await response.json(); // Parse JSON from the response
        if (data.success && data.data) {
          const fetchedItems = data.data.map(item => ({
            ...item,
            deadline: new Date(parseInt(item.endTime)).getTime()
          }));
          const uniqueCategories = new Set(fetchedItems.map(item => item.category));
          setCategories(['All', ...Array.from(uniqueCategories)]);
          setItems(fetchedItems);
        } else {
          throw new Error('Failed to load items');
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, []);


  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Shop</h1>
        <div className="content__body">
          <Col>
          <select className="form-group" onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
            {categories.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
            <Row sm="4">
              {items.filter(item => selectedCategory === 'All' || item.category === selectedCategory).map(item => (
                <ItemCard
                  key={item.id}
                  title={item.title}
                  price={item.price}
                  image={item.image}
                />
              ))}
            </Row>
          </Col>
        </div>
      </div>
    </PageLayout>
  );
};
