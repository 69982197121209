import React, { useCallback, useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { Button, CardImg, CardHeader, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/CardStyle.css";
import { useNavigate } from 'react-router-dom';
import { useCart } from '../components/cartContext';
import { toast } from "react-toastify";

const ItemCard = ({ title, price, image, itemData, onAddToCart }) => {
  const navigate = useNavigate();

  const navigateToItem = () => {
    navigate(`/view-item/${itemData.id}`); // Assuming `id` is the unique identifier for items
  };

  return (
    <Card className="lottery-card my-3" color="secondary" inverse >
      <CardHeader className=''>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div>{title}</div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardImg 
        alt="Card image cap" 
        src={`${image}`}
        style={{ width: "100%", height: "auto", objectFit: "cover" }}
        onError={(e) => e.target.src = 'path_to_fallback_image.jpg'}
      />
      <CardBody>
        <CardTitle tag="h5" onClick={navigateToItem} style={{ cursor: 'pointer' }}>
          <div>View Item</div>
        </CardTitle>
        <CardSubtitle className="mb-2" tag="h1" style={{color:'gold'}}>
        <div className="center">Price: ${price}</div>
        <div className="center">ID: {itemData.id}</div>
        <div className="center">Quantity {itemData.quantity}</div>
        </CardSubtitle>
        <Button color="primary" onClick={() => onAddToCart(itemData)}>
          Add to Cart
        </Button>
      </CardBody>
    </Card>
  );
};

export const AuthShop = () => {
  const [items, setItems] = useState([]);
  const { addItem } = useCart();
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedVendor, setSelectedVendor] = useState('All');
  const [categories, setCategories] = useState(['All']);
  const [vendors, setVendors] = useState([{ id: 'All', name: 'All' }]);

  const handleAddToCart = useCallback((item) => {
    addItem({
      id: item.id,
      name: item.title,
      price: item.price,
      itemid: item.clover_itemid,
      maxQuantity: item.quantity,
      vendor_id: item.consignorid,
      itemType: "shop",
    });
  }, [addItem]);

  useEffect(() => {
    document.title = "Auctions | Auctions";
    const fetchItems = async () => {
      try {
        const response = await fetch('https://api.bid605.com/items/shop/active');
        const data = await response.json(); // Parse JSON from the response
        if (data.success && data.data) {
          const fetchedItems = data.data.map(item => ({
            ...item,
            deadline: new Date(parseInt(item.endTime)).getTime()
          }));
          const uniqueCategories = new Set(fetchedItems.map(item => item.category));
          setCategories(['All', ...Array.from(uniqueCategories)]);
          setItems(fetchedItems);
        } else {
          throw new Error('Failed to load items');
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    const fetchVendors = async () => {
      try {
        const response = await fetch('https://api.bid605.com/vendors');
        const data = await response.json();
        if (data.success && data.data) {
          const vendorData = data.data.map(vendor => ({ id: vendor.id, name: vendor.name }));
          setVendors([{ id: 'All', name: 'All' }, ...vendorData]);
        } else {
          throw new Error('Failed to load vendors');
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    fetchItems();
    fetchVendors();
  }, []);

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Shop</h1>
        <div className="content__body">
          <Col>
            <select className="form-group" onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
              {categories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
            <select className="form-group" onChange={(e) => setSelectedVendor(e.target.value)} value={selectedVendor}>
              {vendors.map((vendor) => (
                <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
              ))}
            </select>

            <Row sm="4">
              {items.filter(item => 
                (selectedCategory === 'All' || item.category === selectedCategory) &&
                (selectedVendor === 'All' || item.consignorid === selectedVendor)
              ).map(item => (
                <ItemCard
                  key={item.id}
                  title={item.title}
                  price={item.price}
                  image={item.image}
                  itemData={item}
                  onAddToCart={handleAddToCart}
                />
              ))}
            </Row>
          </Col>
        </div>
      </div>
    </PageLayout>
  );
};
