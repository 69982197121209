// src/components/cartContext.js
import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { toast } from "react-toastify";

const CartContext = createContext();

const initialState = JSON.parse(localStorage.getItem('cart')) || [];

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      const existingItemIndex = state.findIndex(item => item.id === action.payload.id);
      if (existingItemIndex !== -1) {
        const existingItem = state[existingItemIndex];
        const newQuantity = existingItem.quantity + action.payload.quantity;
        if (newQuantity > existingItem.maxQuantity) {
          toast.error(`Only ${existingItem.maxQuantity} items are available.`);
          return state;
        }
        const updatedItems = [...state];
        updatedItems[existingItemIndex].quantity = newQuantity;
        return updatedItems;
      }
      if (action.payload.quantity > action.payload.maxQuantity) {
        toast.error(`Only ${action.payload.maxQuantity} items are available.`);
        return state;
      }
      return [...state, action.payload];
    }
    case 'REMOVE_ITEM': {
      return state.filter(item => item.id !== action.payload);
    }
    case 'ADJUST_QUANTITY': {
      return state.map(item =>
        item.id === action.payload.id
          ? { 
              ...item, 
              quantity: action.payload.quantity > item.maxQuantity 
                ? item.maxQuantity 
                : action.payload.quantity 
            }
          : item
      );
    }
    case 'CLEAR_CART': {
      return [];
    }
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(state));
  }, [state]);

  const addItem = (item) => {
    const existingItem = state.find(cartItem => cartItem.id === item.id);
    if (existingItem && existingItem.quantity >= existingItem.maxQuantity) {
      toast.error(`Only ${existingItem.maxQuantity} items are available.`);
      return;
    }
    toast.success('Item added to cart');
    dispatch({ type: 'ADD_ITEM', payload: { ...item, quantity: 1 } });
  };

  const removeItem = (id) => {
    toast.error('Item removed from cart');
    dispatch({ type: 'REMOVE_ITEM', payload: id });
  };

  const adjustQuantity = (id, quantity) => {
    const item = state.find(cartItem => cartItem.id === id);
    if (quantity > item.maxQuantity) {
      toast.error(`Only ${item.maxQuantity} items are available.`);
      return;
    }
    toast.info('Quantity adjusted');
    dispatch({ type: 'ADJUST_QUANTITY', payload: { id, quantity } });
  };

  const clearCart = () => {
    toast.warn('Cart cleared');
    dispatch({ type: 'CLEAR_CART' });
  };

  return (
    <CartContext.Provider value={{ cart: state, addItem, removeItem, adjustQuantity, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
