import React, { useEffect, useState, useCallback } from "react";
import { PageLayout } from "../components/page-layout";
import { CountdownTimer } from "../components/countdown-timer";
import { Button, CardImg, CardHeader, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/CardStyle.css";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react"; // Import the useAuth0 hook
import { useTokenProvider } from '../components/tokenProvider'; // Adjust the import path as necessary
import { useCart } from '../components/cartContext';
import { toast } from "react-toastify";

const ItemCard = ({ title, price, image, itemData, onAddToCart }) => {
  const navigate = useNavigate();

  const navigateToItem = () => {
    navigate(`/view-item/${itemData.id}`); // Assuming `id` is the unique identifier for items
  };

  return (
    <Card className="lottery-card my-3" color="secondary" inverse >
      <CardHeader className=''>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div>{title}</div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardImg
        alt="Card image cap"
        src={`https://bid605.com${image}`}
        style={{ width: "100%", height: "auto", objectFit: "cover" }}
        onError={(e) => e.target.src = 'path_to_fallback_image.jpg'}
      />
      <CardBody>
        <CardTitle tag="h5" onClick={navigateToItem} style={{ cursor: 'pointer' }}>
          <div>View Item</div>
        </CardTitle>
        <CardSubtitle className="mb-2" tag="h1" style={{color:'gold'}}>
          <div className="center">${price}</div>
          <div className="center">{itemData.id}</div>
        </CardSubtitle>
        <div className="d-flex justify-content-center align-items-center">
        {itemData.paid === 'unpaid' ? (
          <Button color="primary" onClick={() => onAddToCart(itemData)}>
            Add to Cart
          </Button>
        ) : (
          <Button color="secondary" disabled>
            Paid
          </Button>
        )}
        </div>
      </CardBody>
    </Card>
  );
};

export const Winnings = () => {
  const [items, setItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const token = useTokenProvider();
  const { user } = useAuth0();
  const { addItem } = useCart();
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState(['All']);

  const handleAddToCart = useCallback((item) => {
    if (!cartItems.find(cartItem => cartItem.id === item.id)) {
      addItem({
        id: item.id,
        name: item.title,
        price: item.price,
        itemid: item.clover_itemid,
        maxQuantity: item.quantity,
        vendor_id: item.consignorid,
        itemType: "auction",
      });
      setCartItems(prevCartItems => [...prevCartItems, item]);
    } else {
      toast.error("Item is already in the cart.");
    }
  }, [addItem, cartItems]);

  useEffect(() => {
    document.title = "Auctions | Auctions";
    const fetchItems = async () => {
      if (!token) {
        return null;
      }
      try {
        const response = await fetch('https://api.bid605.com/items/auctions/winnings', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json(); // Parse JSON from the response
        if (data.success && data.data) {
          const fetchedItems = data.data.map(item => ({
            ...item,
            deadline: new Date(parseInt(item.endTime)).getTime()
          }));
          setItems(fetchedItems);
          const uniqueCategories = new Set(fetchedItems.map(item => item.category));
          setCategories(['All', ...Array.from(uniqueCategories)]);
        } else {
          throw new Error('Failed to load items');
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, [token]);

  if (!user) {
    return null;
  }

  if (!token) {
    return null;
  }

  console.log("Winnings token:", token.token);
  console.log("Winnings profileData:", token.profileData);

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Auction Wins</h1>
        <div className="content__body">
          <Col>
          <select className="form-group" onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
            {categories.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
          <div className="btn-group" style={{width:"100%"}}>
            {categories.map((category, index) => (
              <button
                key={index}
                className={`btn ${selectedCategory === category ? 'btn-primary' : 'btn-secondary'}`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
            <Row sm="4">
              {items.filter(item => selectedCategory === 'All' || item.category === selectedCategory).map(item => (
                <ItemCard
                  key={item.id}
                  title={item.title}
                  price={item.price}
                  image={item.image}
                  itemData={item}
                  onAddToCart={handleAddToCart}
                />
              ))}
            </Row>
          </Col>
        </div>
      </div>
    </PageLayout>
  );
};
