import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import img1 from '../assets/images/1.jfif';
import img2 from '../assets/images/2.jfif';
import img3 from '../assets/images/3.jfif';
import img4 from '../assets/images/4.jfif';
import img5 from '../assets/images/5.jfif';

export const CurrentVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [img1, img2, img3, img4, img5];

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await fetch('https://api.bid605.com/vendors', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        if (data.success) {
          setVendors(data.data);
        } else {
          console.error('Failed to fetch vendors:', data.message);
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    fetchVendors();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const containerStyle = {
    width: '100%',
    overflow: 'hidden',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    padding: '20px'
  };

  const fullWidthStyle = {
    width: '100%',

    textAlign: 'center'
  };

  const vendorCardStyle = {
    display: 'inline-block',
    margin: '20px',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    textAlign: 'center',
    width: '300px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  };

  const vendorImageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '8px'
  };

  const headerStyle = {
    backgroundColor: '#1a1a1a',
    color: 'white',
    padding: '10px 0'
  };

  return (
    <PageLayout>
      <div style={containerStyle}>
        {/* Header Section */}
        <div style={{ ...headerStyle, ...fullWidthStyle }}>
          <h1 className="text-white">BB605</h1>
          <p>113 N Splitrock Blvd, Brandon, SD 57005 • 605-582-2033 • info@bb605.com</p>
        </div>

        {/* Vendor List Section */}
        <div style={fullWidthStyle}>
          {vendors.map((vendor, index) => (
            <div key={index} style={vendorCardStyle}>
              <a href={`/vendor-shop?id=${vendor.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <img src={vendor.logo_url} alt={`vendor-${index}`} style={vendorImageStyle} />
                <h3>{vendor.name}</h3>
              </a>
            </div>
          ))}
        </div>
      </div>
    </PageLayout>
  );
};
