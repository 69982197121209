import React from "react";
import { PageFooterHyperlink } from "./page-footer-hyperlink";

export const PageFooter = () => {
  const resourceList = [
    {
      path: "https://www.facebook.com/BoutiqueBid605",
      label: "Facebook",
    },
    {
      path: "https://auth0.com/docs/get-started",
      label: "Privacy Policy",
    },
    {
      path: "https://auth0.com/blog/developers/",
      label: "Terms Of Service",
    },
  ];

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__message">
          <p className="page-footer-message__headline">
            <PageFooterHyperlink path="http://bb605.shop/vendor-application">
              Apply to be a Vendor
            </PageFooterHyperlink>
          </p>
            <p className="page-footer-message__description">
              <PageFooterHyperlink path="https://www.facebook.com/BoutiqueBid605">
                <span>
                  Boutique and Bid 605  <br />
                  113 N Splitrock Blvd, Brandon, SD 57005 <br />
                  Phone: 605-582-2033 <br />
                  Email: support@bb605.shop <br />
                </span>
              </PageFooterHyperlink>
            </p>
          </div>


          <div className="page-footer-info__message">
            <p className="page-footer-message__description">
              <PageFooterHyperlink path="https://www.facebook.com/BoutiqueBid605">
                <span>
                  Business Hours: <br />
                  Tues-Fri: 12-6pm <br />
                  Sat: 10am-6pm <br />
                  Sun: 12-4pm <br />
                </span>
              </PageFooterHyperlink>
            </p>
          </div>


          <div className="page-footer-info__resource-list">
            {resourceList.map((resource) => (
              <div
                key={resource.path}
                className="page-footer-info__resource-list-item"
              >
                <PageFooterHyperlink path={resource.path}>
                  {resource.label}
                </PageFooterHyperlink>
              </div>
            ))}
          </div>
        </div>
        <div className="page-footer-grid__brand">
          <div className="page-footer-brand">
            <PageFooterHyperlink path="https://www.facebook.com/BoutiqueBid605">
            BB605 - Shop Small, Love Local
            </PageFooterHyperlink>
          </div>
        </div>
      </div>
    </footer>
  );
};
