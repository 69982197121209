import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { PageLayout } from "../components/page-layout";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

export const VendorApplicationForm = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    itemType: '',
    contactPhone: '',
    contactEmail: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    firstName: '',
    lastName: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.bid605.com/apply_vendor', formData);
      if (response.data.success) {
        alert('Application submitted successfully');
        setFormData({
          businessName: '',
          itemType: '',
          contactPhone: '',
          contactEmail: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          firstName: '',
          lastName: ''
        });
      } else {
        alert('Failed to submit application');
      }
    } catch (error) {
      console.error('Error submitting application:', error);
      alert('An error occurred while submitting the application');
    }
  };

  return (
    <PageLayout>
    <div className="container">
      <h1>Vendor Application Form</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="businessName">Name of Business</Label>
          <Input
            type="text"
            id="businessName"
            value={formData.businessName}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="itemType">Type of Items Sold</Label>
          <Input
            type="text"
            id="itemType"
            value={formData.itemType}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="contactPhone">Contact Phone</Label>
          <Input
            type="tel"
            id="contactPhone"
            value={formData.contactPhone}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="contactEmail">Contact Email</Label>
          <Input
            type="email"
            id="contactEmail"
            value={formData.contactEmail}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="address">Address</Label>
          <Input
            type="text"
            id="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="city">City</Label>
          <Input
            type="text"
            id="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="state">State</Label>
          <Input
            type="text"
            id="state"
            value={formData.state}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="zip">ZIP Code</Label>
          <Input
            type="text"
            id="zip"
            value={formData.zip}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="firstName">First Name</Label>
          <Input
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="lastName">Last Name</Label>
          <Input
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <Button type="submit" color="primary">Submit Application</Button>
      </Form>
    </div>
    </PageLayout>
  );
};