import React, { useCallback, useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { CountdownTimer } from "../components/countdown-timer";
import { Button, CardImg, CardHeader, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/CardStyle.css";
import { useNavigate } from 'react-router-dom';
import { useCart } from '../components/cartContext';
import { toast } from "react-toastify";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useAuth0 } from "@auth0/auth0-react"; // Import the useAuth0 hook
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { Form, FormGroup, Label, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { useAuth0 } from "@auth0/auth0-react";

const HomeTab = ({ token, profileData }) => {
  const [vendorInfo, setVendorInfo] = useState(null);
  //const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchVendorInfo = async () => {
      if (!token) {
        return;
      }
      if (!profileData) {
        return;
      }
      try {
        //const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/get_vendor_info', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ vendorId: profileData.vendor_id }) // Replace with the actual vendor ID
        });

        const data = await response.json();
        console.log('Vendor info:', data);
        if (data.success) {
          setVendorInfo(data.data);
        } else {
          console.error('Failed to fetch vendor info:', data.message);
        }
      } catch (error) {
        console.error('Error fetching vendor info:', error);
      }
    };

    fetchVendorInfo();
  }, [token, profileData]);

  return (
    <div>
      <h2 className="text-black">Home Tab</h2>
      {vendorInfo ? (
        <div>
          <p>ID: {vendorInfo.id}</p>
          <p>Name: {vendorInfo.name}</p>
          <p>Address: {vendorInfo.address}</p>
          <p>Phone: {vendorInfo.phone}</p>
          <p>Email: {vendorInfo.email}</p>
          <p>Business Percentage: {vendorInfo.business_percentage}%</p>
          <p>Consignor Percentage: {vendorInfo.consignor_percentage}%</p>
        </div>
      ) : (
        <p>Loading vendor information...</p>
      )}
    </div>
  );
};

const ShopItemManagement = ({ token }) => {
    const [items, setItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const { getAccessTokenSilently } = useAuth0();
  
    useEffect(() => {
      const fetchItems = async () => {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch('https://api.bid605.com/get_vendor_shop_items', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const data = await response.json();
          console.log('ShopItemManagement Data:', data);
          setItems(data); // Ensure items is an array
          console.log('ShopItemManagement Items:', data.data);
        } catch (error) {
          console.error('Error fetching items:', error);
        }
      };
  
      fetchItems();
    }, [getAccessTokenSilently]);
  
    const handleDelete = async (itemId) => {
      if (!window.confirm("Are you sure you want to delete this item?")) return;
  
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/shop/items/delete', {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ itemId })
        });
  
        const data = await response.json();
        if (data.success) {
          alert('Item deleted successfully');
          setItems(items.filter(item => item.id !== itemId));
        } else {
          alert('Failed to delete item');
        }
      } catch (error) {
        console.error('Error deleting item:', error);
        alert('An error occurred while deleting the item');
      }
    };
  
    const handleEdit = (item) => {
      setSelectedItem(item);
      setIsModalOpen(true);
    };
  
    const handleEditSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/shop/items/edit', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(selectedItem)
        });
  
        const data = await response.json();
        if (data.success) {
          alert('Item updated successfully');
          setItems(items.map(item => (item.id === selectedItem.id ? selectedItem : item)));
          setIsModalOpen(false);
        } else {
          alert('Failed to update item');
        }
      } catch (error) {
        console.error('Error updating item:', error);
        alert('An error occurred while updating the item');
      }
    };
    const filteredItems = items.filter(item => {
      return Object.keys(item).some(key =>
        item[key] != null && item[key].toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    return (
      <div className="container">
        <h1 className="mb-4">Shop Item List</h1>
        <Input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        className="mb-4"
        />
        <Table bordered className="mb-4">
          <thead>
            <tr>
              <th className="text-white">ID</th>
              <th className="text-white">SKU</th>
              <th className="text-white">Title</th>
              <th className="text-white">Category</th>
              <th className="text-white">Description</th>
              <th className="text-white">Quantity</th>
              <th className="text-white">Price</th>
              <th className="text-white">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map(item => (
              <tr key={item.id}>
                <td className="text-black">{item.id}</td>
                <td className="text-black">{item.clover_itemid}</td>
                <td className="text-black">{item.title}</td>
                <td className="text-black">{item.category}</td>
                <td className="text-black">{item.description}</td>
                <td className="text-black">{item.quantity}</td>
                <td className="text-black">{item.price}</td>
                <td>
                  <Button color="warning" onClick={() => handleEdit(item)}>Edit</Button>
                  <Button color="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
  
        {selectedItem && (
          <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
            <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Item</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleEditSubmit}>
                <FormGroup>
                  <Label for="title">Title</Label>
                  <Input
                    type="text"
                    id="title"
                    value={selectedItem.title}
                    onChange={(e) => setSelectedItem({ ...selectedItem, title: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="category">Category</Label>
                  <Input
                    type="text"
                    id="category"
                    value={selectedItem.category}
                    onChange={(e) => setSelectedItem({ ...selectedItem, category: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    id="description"
                    value={selectedItem.description}
                    onChange={(e) => setSelectedItem({ ...selectedItem, description: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="quantity">Quantity</Label>
                  <Input
                    type="number"
                    id="quantity"
                    value={selectedItem.quantity}
                    onChange={(e) => setSelectedItem({ ...selectedItem, quantity: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="price">Price</Label>
                  <Input
                    type="number"
                    id="price"
                    value={selectedItem.price}
                    onChange={(e) => setSelectedItem({ ...selectedItem, price: e.target.value })}
                    required
                  />
                </FormGroup>
                <Button color="primary" type="submit">Save</Button>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
};

const AuctionItemManagement = ({ token }) => {
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/get_vendor_auction_items', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        console.log('ShopItemManagement Data:', data);
        setItems(data); // Ensure items is an array
        console.log('ShopItemManagement Items:', data.data);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, [getAccessTokenSilently]);

  const handleDelete = async (itemId) => {
    if (!window.confirm("Are you sure you want to delete this item?")) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/auction/items/delete', {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ itemId })
      });

      const data = await response.json();
      if (data.success) {
        alert('Item deleted successfully');
        setItems(items.filter(item => item.id !== itemId));
      } else {
        alert('Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('An error occurred while deleting the item');
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/auction/items/edit', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(selectedItem)
      });

      const data = await response.json();
      if (data.success) {
        alert('Item updated successfully');
        setItems(items.map(item => (item.id === selectedItem.id ? selectedItem : item)));
        setIsModalOpen(false);
      } else {
        alert('Failed to update item');
      }
    } catch (error) {
      console.error('Error updating item:', error);
      alert('An error occurred while updating the item');
    }
  };
  const filteredItems = items.filter(item => {
    return Object.keys(item).some(key =>
      item[key] != null && item[key].toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
    return (
      <div className="container">
        <h1 className="mb-4">Auction Item List</h1>
        <Input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        className="mb-4"
        />
        <Table bordered className="mb-4">
          <thead>
            <tr>
              <th className="text-white">ID</th>
              <th className="text-white">Title</th>
              <th className="text-white">Category</th>
              <th className="text-white">Description</th>
              <th className="text-white">Quantity</th>
              <th className="text-white">Price</th>
              <th className="text-white">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map(item => (
              <tr key={item.id}>
                <td className="text-black">{item.id}</td>
                <td className="text-black">{item.title}</td>
                <td className="text-black">{item.category}</td>
                <td className="text-black">{item.description}</td>
                <td className="text-black">{item.quantity}</td>
                <td className="text-black">{item.price}</td>
                <td>
                  <Button color="warning" onClick={() => handleEdit(item)}>Edit</Button>
                  <Button color="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
  
        {selectedItem && (
          <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
            <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Item</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleEditSubmit}>
                <FormGroup>
                  <Label for="title">Title</Label>
                  <Input
                    type="text"
                    id="title"
                    value={selectedItem.title}
                    onChange={(e) => setSelectedItem({ ...selectedItem, title: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="category">Category</Label>
                  <Input
                    type="text"
                    id="category"
                    value={selectedItem.category}
                    onChange={(e) => setSelectedItem({ ...selectedItem, category: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    id="description"
                    value={selectedItem.description}
                    onChange={(e) => setSelectedItem({ ...selectedItem, description: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="quantity">Quantity</Label>
                  <Input
                    type="number"
                    id="quantity"
                    value={selectedItem.quantity}
                    onChange={(e) => setSelectedItem({ ...selectedItem, quantity: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="price">Price</Label>
                  <Input
                    type="number"
                    id="price"
                    value={selectedItem.price}
                    onChange={(e) => setSelectedItem({ ...selectedItem, price: e.target.value })}
                    required
                  />
                </FormGroup>
                <Button color="primary" type="submit">Save</Button>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
};

const AddItem = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [variants, setVariants] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleAddVariant = () => {
    setVariants([...variants, { name: '', value: '', quantity: '', price: '' }]);
  };

  const handleVariantChange = (index, field, value) => {
    const newVariants = [...variants];
    newVariants[index][field] = value;
    setVariants(newVariants);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', image);
    formData.append('category', category);
    formData.append('description', description);
    formData.append('quantity', quantity);
    formData.append('price', price);
    formData.append('variants', JSON.stringify(variants)); // Add variants as JSON string

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/items/shop/add', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        alert('Item added successfully');
      } else {
        alert('Failed to add item');
      }
    } catch (error) {
      console.error('Error adding item:', error);
      alert('An error occurred while adding the item');
    }
  };

  return (
    <div className="container">
      <h1 className="mb-4">Add Shop Item</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="image">Image</Label>
          <Input type="file" id="image" onChange={handleImageChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="category">Category</Label>
          <Input type="select" id="category" value={category} onChange={(e) => setCategory(e.target.value)} required >
            <option value="">Select Category</option>
            <option value="Furniture">Furniture</option>
            {/* Add other categories as needed */}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="description">Description</Label>
          <Input type="textarea" id="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="quantity">Quantity</Label>
          <Input type="number" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="price">Price</Label>
          <Input type="number" id="price" value={price} onChange={(e) => setPrice(e.target.value)} required />
        </FormGroup>
        <h3>Variants</h3>
        <Button onClick={handleAddVariant} color="secondary" className="mb-3">Add Variant</Button>
        {variants.map((variant, index) => (
          <div key={index} className="mb-3">
            <FormGroup>
              <Label for={`variant-name-${index}`}>Variant Name</Label>
              <Input
                type="text"
                id={`variant-name-${index}`}
                value={variant.name}
                onChange={(e) => handleVariantChange(index, 'name', e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for={`variant-value-${index}`}>Variant Value</Label>
              <Input
                type="text"
                id={`variant-value-${index}`}
                value={variant.value}
                onChange={(e) => handleVariantChange(index, 'value', e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for={`variant-quantity-${index}`}>Variant Quantity</Label>
              <Input
                type="number"
                id={`variant-quantity-${index}`}
                value={variant.quantity}
                onChange={(e) => handleVariantChange(index, 'quantity', e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for={`variant-price-${index}`}>Variant Price</Label>
              <Input
                type="number"
                id={`variant-price-${index}`}
                value={variant.price}
                onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
                required
              />
            </FormGroup>
            {index < variants.length - 1 && <hr />} {/* Add a divider between variants */}
          </div>
        ))}
        <Button color="primary" type="submit">Add Item</Button>
      </Form>
    </div>
  );
};

const AddAuctionItem = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [startingPrice, setStartingPrice] = useState('');
  const [consignor, setConsignor] = useState('');

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', image);
    formData.append('category', category);
    formData.append('description', description);
    formData.append('startTime', startTime.toISOString());
    formData.append('endTime', endTime.toISOString());
    formData.append('startingPrice', startingPrice);
    formData.append('consignor', consignor);

    // Submit formData to the server
    console.log('Form data:', formData);
  };

  return (
    <div className="container">
      <h1 className="mb-4">Add Auction</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="image">Image</Label>
          <Input type="file" id="image" onChange={handleImageChange} required />
        </FormGroup>
        <FormGroup>
          <Label for="category">Category</Label>
          <Input type="select" id="category" value={category} onChange={(e) => setCategory(e.target.value)} required >
            <option value="">Select Category</option>
            <option value="Furniture">Furniture</option>
            {/* Add other categories as needed */}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="description">Description</Label>
          <Input type="textarea" id="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="startTime">Auction Start Time</Label>
          <DatePicker selected={startTime} onChange={(date) => setStartTime(date)} showTimeSelect dateFormat="Pp" className="form-control" required />
        </FormGroup>
        <FormGroup>
          <Label for="endTime">Auction End Time</Label>

          <DatePicker selected={endTime} onChange={(date) => setEndTime(date)} showTimeSelect dateFormat="Pp" className="form-control" required />
        </FormGroup>
        <FormGroup>
          <Label for="startingPrice">Starting Price</Label>
          <Input type="number" id="startingPrice" value={startingPrice} onChange={(e) => setStartingPrice(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label for="consignor">Consignor</Label>
          <Input type="select" id="consignor" value={consignor} onChange={(e) => setConsignor(e.target.value)} required >
            <option value="">Select Consignor</option>
            <option value="Boutique and Bid 605">Boutique and Bid 605</option>
            {/* Add other consignors as needed */}
          </Input>
        </FormGroup>
        <Button color="primary" type="submit">Add</Button>
      </Form>
    </div>
  );
};

const VendorOrderManagement = ({ token }) => {
  const [soldItems, setSoldItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchSoldItems = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch('https://api.bid605.com/vendor/sold_items', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          setSoldItems(data.data);
        } else {
          toast.error('Failed to fetch sold items');
        }
      } catch (error) {
        console.error('Error fetching sold items:', error);
        toast.error('Error fetching sold items');
      }
    };

    fetchSoldItems();
  }, [getAccessTokenSilently]);

  const handleUpdateStatus = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.bid605.com/vendor/sold_items/update_status', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ soldItemId: selectedItem.id, status })
      });
      const data = await response.json();
      if (data.success) {
        toast.success('Order status updated successfully');
        setSoldItems(soldItems.map(item => item.id === selectedItem.id ? { ...item, status } : item));
        setIsModalOpen(false);
      } else {
        toast.error('Failed to update order status');
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error('Error updating order status');
    }
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setStatus(item.status);
    setIsModalOpen(true);
  };

  return (
    <div className="container">
      <h1 className="mb-4">Vendor Order Management</h1>
      <Table bordered className="mb-4">
        <thead>
          <tr>
            <th className="text-white">ID</th>
            <th className="text-white">Item ID</th>
            <th className="text-white">Item Name</th>
            <th className="text-white">Customer</th>
            <th className="text-white">Quantity</th>
            <th className="text-white">TXID</th>
            <th className="text-white">Status</th>
            <th className="text-white">Actions</th>
          </tr>
        </thead>
        <tbody>
        {soldItems.map(item => {
          const buyerInfo = item.buyer_info || {};
          const itemName = item.regular_item_name || item.auction_item_name || 'Unknown';

          return (
            <tr key={item.id}>
              <td className="text-black">{item.id}</td>
              <td className="text-black">{item.item_id}</td>
              <td className="text-black">{itemName}</td>
              <td className="text-black">
                {buyerInfo.name} <br />
                {buyerInfo.email} <br />
                {buyerInfo.address} <br />
                {buyerInfo.city} <br />
                {buyerInfo.state} <br />
                {buyerInfo.zip} <br />
              </td>
              <td className="text-black">{item.quantity}</td>
              <td className="text-black">{item.tx_id}</td>
              <td className="text-black">{item.status}</td>
              <td>
                <Button color="warning" onClick={() => openModal(item)}>Update Status</Button>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>

      {selectedItem && (
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
          <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Update Order Status</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleUpdateStatus}>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                >
                  <option value="Pending">Pending</option>
                  <option value="Processed">Processed</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                </Input>
              </FormGroup>
              <Button color="primary" type="submit">Update</Button>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export const VendorManagement = ({token, profileData}) => {
  const [activeKey, setActiveKey] = useState("HomeTab");

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {

  }, []);

  console.log('VendorManagement token: ', token);
  console.log('VendorManagement profileData: ', profileData);
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">Vendor Management</h1>
        <div className="content__body">
          <Col>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" activeKey={activeKey} onSelect={handleSelect} >
            <Tab eventKey="HomeTab" title="Home"><HomeTab profileData={profileData} token={token}/></Tab>
            <Tab eventKey="ShopItemManagement" title="Manage Shop Items"><ShopItemManagement token={token}/></Tab>
            <Tab eventKey="AuctionItemManagement" title="Manage Auctions Items"><AuctionItemManagement token={token}/></Tab>
            <Tab eventKey="AddItem" title="Add Item"><AddItem token={token}/></Tab>
            <Tab eventKey="AddAuctionItem" title="Add Auction"><AddAuctionItem token={token}/></Tab>
            <Tab eventKey="VendorOrderManagement" title="Order Management"><VendorOrderManagement token={token}/></Tab>
            </Tabs>
          </Col>
        </div>
      </div>
    </PageLayout>
  );
};
