// useTokenProvider.js (or wherever your token fetching logic resides)
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useTokenProvider = () => {
  const [token, setToken] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          const audience = 'https://api.bid605.com';
          const accessToken = await getAccessTokenSilently({ audience });
          setToken(accessToken);
        } catch (error) {
          console.error('Error getting access token:', error);
        }
      }
    };

    fetchToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  return token;
};
