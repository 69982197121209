import React, { useState, useEffect } from 'react';
import { useCart } from '../components/cartContext';
import { Form, Button, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { PageLayout } from "../components/page-layout";
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from "react-toastify";
import '../styles/Clover.css';
import axios from 'axios';

const CITY_TAX_RATE = 0.02; // 2% city tax
const STATE_TAX_RATE = 0.042; // 4.2% state tax
const PROCESSING_FEE_RATE = 0.03; // 3% processing fee

const USPS_RATES = {
  priorityMail: {
    retail: 9.25,
    commercial: 7.90,
    name: 'Priority Mail'
  },
  priorityMailFlatRateEnvelope: {
    retail: 9.85,
    commercial: 8.50,
    name: 'Priority Mail Flat Rate Envelope'
  },
  firstClassMail: {
    retail: 0.68,
    commercial: 0.507,
    name: 'First-Class Mail'
  },
  groundAdvantage: {
    retail: 5.00,
    commercial: 3.79,
    name: 'Ground Advantage'
  }
  // Add more rates as needed
};

const calculateSubtotal = (cart) => {
  return cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
};

const calculateCityTax = (subtotal) => {
  return subtotal * CITY_TAX_RATE;
};

const calculateStateTax = (subtotal) => {
  return subtotal * STATE_TAX_RATE;
};

const calculateProcessingFee = (subtotal) => {
  return subtotal * PROCESSING_FEE_RATE;
};

const calculateTotal = (subtotal, cityTax, stateTax, processingFee, shippingCost) => {
  const total = subtotal + cityTax + stateTax + processingFee + shippingCost;
  return (Math.round(total * 100) / 100);
};

export const ShoppingCart = ({ token, profileData }) => {
  const { cart, removeItem, adjustQuantity, clearCart } = useCart();
  const [isModalOpen, setModalOpen] = useState(false);
  const [cloverObject, setCloverObject] = useState('');
  const [isInitialized, setIsInitialized] = useState('');
  const [clientIp, setClientIp] = useState('');
  const [shippingOption, setShippingOption] = useState('localPickup');
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingPackage, setShippingPackage] = useState(0);

  console.log('profileData:', profileData);
  const formStyle = {
    maxHeight: "2em",
  };

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(initializeCloverElements, 1000); // Adjust timeout as needed for your modal transition
    }
  }, [isModalOpen]);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setClientIp(data.ip);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };

    fetchIp();
  }, []);

  useEffect(() => {
    const updateShippingCost = async () => {
      if (shippingOption === 'shipToMe') {
        // Adjust this logic based on your needs
        const shippingMethod = 'priorityMail'; // Default to priority mail
        const cost = USPS_RATES[shippingMethod].retail; // You can add logic to choose between retail and commercial
        const shippingPack = USPS_RATES[shippingMethod].name; // You can add logic to choose between retail and commercial
        setShippingPackage(shippingPack);
        setShippingCost(cost);
      } else {
        setShippingCost(0);
      }
    };

    updateShippingCost();
  }, [shippingOption, cart]);

  const handleQuantityChange = (e, id, maxQuantity) => {
    const value = parseInt(e.target.value, 10);
    console.log('e:', e);
    console.log('Max Quantity:', maxQuantity);
    console.log('ID:', id);
    if (value > maxQuantity) {
      toast.error(`Maximum available quantity: ${maxQuantity}`);
    } else if (value > 0) {
      adjustQuantity(id, value);
    }
  };

  const handleCheckout = () => {
    console.log('Proceeding to checkout...');
    console.log('Cart:', cart);

    const subtotal = calculateSubtotal(cart);
    const cityTax = calculateCityTax(subtotal);
    const stateTax = calculateStateTax(subtotal);
    const processingFee = calculateProcessingFee(subtotal);
    const total = calculateTotal(subtotal, cityTax, stateTax, processingFee, shippingCost);

    console.log('Proceeding to checkout...');
    console.log('Cart:', cart);
    console.log('Subtotal:', subtotal.toFixed(2));
    console.log('City Tax:', cityTax.toFixed(2));
    console.log('State Tax:', stateTax.toFixed(2));
    console.log('Processing Fee:', processingFee.toFixed(2));
    console.log('Shipping Cost:', shippingCost.toFixed(2));
    console.log('Total:', total.toFixed(2));

    // Mock Clover checkout session URL. Replace this with an actual URL generated by your backend.
    const generatedUrl = `https://sandbox.dev.clover.com/checkout?total=${total}`;

    //setCheckoutUrl(generatedUrl);
    setModalOpen(true);
  };

  const toggleModal = () => {
    console.log('Toggling modal...');
    setModalOpen(!isModalOpen);
  };

  const subtotal = calculateSubtotal(cart);
  const cityTax = calculateCityTax(subtotal);
  const stateTax = calculateStateTax(subtotal);
  const processingFee = calculateProcessingFee(subtotal);
  const total = calculateTotal(subtotal, cityTax, stateTax, processingFee, shippingCost);

  const initializeCloverElements = () => {
    //if (isInitialized) return;
    const clover = new window.Clover('38128844ba781f925e5eb257b6e25f86');
    setCloverObject(clover);
    const styles = {
      base: {
        fontSize: '16px',
        color: '#495057',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        '::placeholder': {
          color: '#6c757d'
        },
        padding: '10px 12px',  // Ensures padding within input fields for better text visibility
      },
      invalid: {
        color: '#dc3545',
        '::placeholder': {
          color: '#f66d9b'
        }
      }
    };
    const elements = clover.elements(styles);

    const cardNumber = elements.create('CARD_NUMBER', styles);
    const cardName = elements.create('CARD_NAME', styles)
    const cardDate = elements.create('CARD_DATE');
    const cardCvv = elements.create('CARD_CVV');
    const cardPostalCode = elements.create('CARD_POSTAL_CODE');
    const cardStreetAddress = elements.create('CARD_STREET_ADDRESS', styles);

    cardNumber.mount('#card-number');
    cardName.mount('#card-name');
    cardDate.mount('#card-date');
    cardCvv.mount('#card-cvv');
    cardPostalCode.mount('#card-postal-code');
    cardStreetAddress.mount('#card-street-address');

    const cardResponse = document.getElementById('card-response');

    const paymentReqData = {
      total: {
        label: 'Pay Button',
        amount: 0,
      },
      // Default buttonType is 'long' for button with card brand & last 4 digits
      options: {
        button: {
          buttonType: 'long' // For button without additional text
        }
      }
    };
      
    // const paymentRequest = clover.paymentRequest(paymentReqData);
    const paymentRequestButton = elements.create('PAYMENT_REQUEST_BUTTON', {
      paymentReqData
    });
    paymentRequestButton.mount('#payment-request-button');

    
    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async function (event) {
      event.preventDefault();
      /*
      try {
        const result = await clover.createToken();
        if (result.errors) {
          console.error('Clover Tokenization Error:', result.errors);
        } else {
          console.log('Clover Token:', result.token);

        }
      } catch (error) {
        console.error('Error:', error);
      }
      */
    });
    setIsInitialized(true);
  };

  const handlePaymentSubmit = async () => {
    let cloverToken = '';
    try {
      const result = await cloverObject.createToken();
      if (result.errors) {
        console.error('Clover Tokenization Error:', result.errors);
        return; // Exit if there is an error with tokenization
      } else {
        console.log('Clover result.token:', result.token);
        cloverToken = result.token;
      }
    } catch (error) {
      console.error('Error:', error);
      return; // Exit if there is an exception during token creation
    }

    const subtotal = calculateSubtotal(cart);
    const cityTax = calculateCityTax(subtotal);
    const stateTax = calculateStateTax(subtotal);
    const processingFee = calculateProcessingFee(subtotal);
    const total = calculateTotal(subtotal, cityTax, stateTax, processingFee, shippingCost);

    const payload = {
      merchantId: '7W1SEJR62JY21',
      items: cart.map(item => ({ id: item.itemid, price: item.price, quantity: item.quantity, name: item.name, type: item.itemType, vendor_id: item.vendor_id})),
      totalAmount: total.toFixed(2),
      sourceToken: cloverToken,
      currency: 'USD',
      ipaddress: clientIp
    };
    console.log('Payment payload:', payload);
    try {
          const response = await fetch('https://api.bid605.com/checkout', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}` // Assuming you manage JWT for user sessions
              },
              body: JSON.stringify(payload)
          });

          const data = await response.json();
          if (response.ok) {
              toast.success('Checkout successful!');
              console.log('Checkout data:', data);
              clearCart();
          } else {
              throw new Error(data.message);
          }
      } catch (error) {
          console.error('Checkout failed:', error);
          toast.error(`Checkout failed: ${error.message || 'Unknown error'}`);
      }
  };

  return (
    <PageLayout>
      <div className="container" style={{ padding: '10px', textAlign: 'center' }}>
        <h1>Shopping Cart</h1>
        <div className="table-responsive" style={{ display: 'flex', margin: '0 auto' }}>
          <Table className="table table-striped" style={{ margin: '0 auto' }}>
            <thead>
              <tr>
                <th>Item</th>
                <th>Price</th>
                <th>Item ID</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cart.map(item => (
                <tr key={item.id}>
                  <td style={{ background: "white" }}>{item.name}</td>
                  <td style={{ background: "white" }}>{item.price}</td>
                  <td style={{ background: "white" }}>{item.itemid}-{item.vendor_id}</td>
                  <td style={{ background: "white" }}><input type="number" value={item.quantity} min="1" onChange={e => handleQuantityChange(e, item.id, item.maxQuantity)} /></td>
                  <td style={{ background: "white" }}><Button color="danger" onClick={() => removeItem(item.id)}> Remove </Button> </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {cart.length > 0 ? (
          <div style={{ margin: '0 auto', display: 'justify', textAlign: 'left' }}>
            <div>
              <h4>Delivery Method</h4>
              <Form>
                <label>
                  <input type="radio" value="localPickup" checked={shippingOption === 'localPickup'} onChange={() => setShippingOption('localPickup')} />
                  Local Pickup
                </label>
                <label>
                  <input type="radio" value="shipToMe" checked={shippingOption === 'shipToMe'} onChange={() => setShippingOption('shipToMe')} />
                  Ship to Me
                </label>
              </Form>
            </div>
            <Table borderless className="table table-bordered" style={{ margin: '0 auto' }}>
              <thead>
                <tr>
                  <th></th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ background: "white" }}><strong>Subtotal</strong></td>
                  <td style={{ background: "white" }}>${subtotal.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style={{ background: "white" }}><strong>City Tax ({CITY_TAX_RATE * 100}%):</strong></td>
                  <td style={{ background: "white" }}>${cityTax.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style={{ background: "white" }}><strong>State Tax ({STATE_TAX_RATE * 100}%):</strong></td>
                  <td style={{ background: "white" }}>${stateTax.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style={{ background: "white" }}><strong>Processing Fee ({PROCESSING_FEE_RATE * 100}%):</strong></td>
                  <td style={{ background: "white" }}>${processingFee.toFixed(2)}</td>
                </tr>
                {shippingOption === 'shipToMe' && (
                  <tr>
                    <td style={{ background: "white" }}><strong>Shipping Cost: ({shippingPackage})</strong></td>
                    <td style={{ background: "white" }}>${shippingCost.toFixed(2)}</td>
                  </tr>
                )}
                <tr>
                  <td style={{ background: "white" }}><strong>Total</strong></td>
                  <td style={{ background: "white" }}>${total.toFixed(2)}</td>
                </tr>
              </tbody>
            </Table>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Button color="primary" onClick={handleCheckout}>
                Checkout
              </Button>
              <Button color="secondary" onClick={clearCart}>
                Clear Cart
              </Button>
            </div>

            <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg" style={{ display: isModalOpen ? 'block' : 'none' }}>
              <ModalHeader toggle={toggleModal}>Checkout</ModalHeader>
              <ModalBody>
                <div className='col-lg-12 form-row top-row' style={{ size: "100%", width: "100%" }} >
                  <div style={{ minWidth: "105px", minHeight: "55px" }} id='payment-request-button' className='col-lg-12 form-row top-row payment-request-button full-width' ></div>
                </div>

                <div className='hr'>
                  <span style={{ justifyContent: "center" }}>
                    Or Pay with Card
                  </span>
                </div>
                <div className="container">
                  <Form id="payment-form" >
                    <div style={formStyle} className="form-row">
                      <div id="card-number" className="field third-width card-number"></div>
                      <div className="input-errors" id="card-number-errors" role="alert"></div>
                    </div>

                    <div style={formStyle} className="form-row">
                      <div id="card-name" className="field third-width card-name"></div>
                      <div className="input-errors" id="card-name-errors" role="alert"></div>
                    </div>

                    <div style={formStyle} className="form-row">
                      <div id="card-date" className="field third-width"></div>
                      <div className="input-errors" id="card-date-errors" role="alert"></div>
                    </div>

                    <div style={formStyle} className="form-row">
                      <div id="card-cvv" className="field third-width"></div>
                      <div className="input-errors" id="card-cvv-errors" role="alert"></div>
                    </div>

                    <div style={formStyle} className="form-row">
                      <div id="card-postal-code" className="field third-width"></div>
                      <div className="input-errors" id="card-postal-code-errors" role="alert"></div>
                    </div>

                    <div style={formStyle} className="form-row">
                      <div id="card-street-address" className="field third-width"></div>
                      <div className="input-errors" id="card-street-address-errors" role="alert"></div>
                    </div>
                    <div id='card-response' role='alert'>
                    </div>
                    <Button className="center" type="submit" color="primary" onClick={handlePaymentSubmit}>
                      Submit Payment
                    </Button>
                  </Form>
                </div>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          <p>Your cart is empty</p>
        )}
      </div>
    </PageLayout>
  );
};
