import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import {CurrentVendors} from "./pages/current-vendors";

import { PublicAuctions } from "./pages/public-auctions";
import { AuthAuctions } from "./pages/auth-auctions";

import { VendorApplicationForm  } from "./pages/vendor-application";
import { PublicShop } from "./pages/public-shop";
import { AuthShop } from "./pages/auth-shop";

import { PreviousAuctions } from "./pages/previous-auctions";
import { Winnings } from "./pages/winnings";

import { ViewItem } from "./pages/view-item";
import { ShoppingCart } from "./pages/shopping-cart";

import { Administration } from "./pages/administration";
import { VendorManagement } from "./pages/vendor-management";
import { VendorShop } from "./pages/vendor-shop";

import { useTokenProvider  } from './components/tokenProvider'; // Adjust the import path as necessary

import { ToastContainer} from 'react-toastify'; // Assuming you're using react-toastify
import 'react-toastify/dist/ReactToastify.css';


export const App = () => {
  const { isLoading } = useAuth0();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const token = useTokenProvider ();
  const [profileData, setProfileData] = useState(null);
  

  useEffect(() => {
    if (!token) return;
    if (profileData) return;
    const verifyUser = async () => {
      console.log("Verifying user...");
      if (isAuthenticated && user) {
        const accessToken = await getAccessTokenSilently();
        ///we need to check if user exists/ and if they do return their profile in one call
        const response = fetch( `${process.env.REACT_APP_API_URL}/user/check`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ user }) // Minimal user info
        })
        .then(response => response.json())
        .then(data => {
          console.log("User verification:", data);
          if (data && data.userData) {
            setProfileData(data.userData);
          }
        })
        .catch(error => {
          console.error('Error verifying user:', error);
        });
      }
    };

    verifyUser();
  }, [isAuthenticated, user, getAccessTokenSilently, token]);

  console.log('App token: ', token);
  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    
    <>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/auctions" element={<PublicAuctions />} />
      <Route path="/shop" element={<PublicShop />} />
      <Route path="/previous" element={<PreviousAuctions />} />
      <Route path="/vendor-application" element={<VendorApplicationForm  />} />
      <Route path="/current-vendors" element={<CurrentVendors  />} />

      {/* <Route path="/profile" element={<AuthenticationGuard component={ProfilePage} />} /> */}
      <Route path="/profile" element={<AuthenticationGuard component={() => <ProfilePage token={token} profileData={profileData} />}  />} />
      <Route path="/auth-auctions" element={<AuthenticationGuard component={() => <AuthAuctions token={token} profileData={profileData} />}  />} />
      <Route path="/auth-shop" element={<AuthenticationGuard component={() => <AuthShop token={token} profileData={profileData} />}  />} />
      <Route path="/winnings" element={<AuthenticationGuard component={() => <Winnings token={token} profileData={profileData} />}  />} />
      <Route path="/cart" element={<AuthenticationGuard component={() => <ShoppingCart token={token} profileData={profileData} />}  />} />
      <Route path="/administration" element={<AuthenticationGuard component={() => <Administration token={token} profileData={profileData} />}  />} />
      <Route path="/vendor-management" element={<AuthenticationGuard component={() => <VendorManagement token={token} profileData={profileData} />}  />} />
      <Route path="/vendor-shop" element={<AuthenticationGuard component={() => <VendorShop token={token} profileData={profileData} />}  />} />

      {/*<Route path="/view-item/:itemId" component={ViewItem} />*/}
      <Route path="/view-item/:itemId" element={<ViewItem />} />

      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    <ToastContainer />
    
    </>
  );
};
