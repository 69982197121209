import React, { useEffect, useState } from "react";
import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";
import { PageFooter } from "../components/page-footer";
import Marquee from 'react-fast-marquee';
import { PageLayout } from "../components/page-layout";
import { useLocation } from 'react-router-dom';
import img1 from '../assets/images/1.jfif';
import img2 from '../assets/images/2.jfif';
import img3 from '../assets/images/3.jfif';
import img4 from '../assets/images/4.jfif';
import img5 from '../assets/images/5.jfif';

export const HomePage = () => {
  const [vendors, setVendors] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [img1, img2, img3, img4, img5];
  const location = useLocation();

    useEffect(() => {
      const fetchVendors = async () => {
        try {
          const response = await fetch('https://api.bid605.com/vendors', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          const data = await response.json();
          if (data.success) {
            setVendors(data.data);
          } else {
            console.error('Failed to fetch vendors:', data.message);
          }
        } catch (error) {
          console.error('Error fetching vendors:', error);
        }
      };

      fetchVendors();
    }, []);
    
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
      }, 5000);

      return () => clearInterval(interval);
    }, [images.length]);

    const handleVendorClick = (vendorId) => {
      //history.push(`/vendor-shop?id=${vendorId}`); // Navigate to VendorShop page
    };


  const containerStyle = {
    width: '100%',
    overflow: 'hidden',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif'
  };

  const fullWidthStyle = {
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    marginRight: 'calc(50% - 50vw)',
    textAlign: 'center'
  };

  const marqueeImageStyle = {
    margin: '10px 10px',
    height: '150px'
  };

  const headerStyle = {
    backgroundColor: '#1a1a1a',
    color: 'white',
    padding: '10px 0'
  };

  const paragraphStyle = {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    fontSize: '18px'
  };

  const vendorSectionStyle = {
    position: 'relative',
    display: 'inline-block',
    marginTop: '20px',
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '400px'
  };

  const vendorImageStyle = {
    width: '100%',
    height: 'auto'
  };

  const vendorTextStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    textShadow: '2px 2px 4px #000000'
  };

  const buttonStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    marginTop: '10px',
    backgroundColor: '#635dff',
    color: 'white',
    textDecoration: 'none',
    borderRadius: '5px'
  };

  return (
    <>

      <PageLayout>
      <div style={containerStyle}>
        {/* Header Section */}
        <div style={{ ...headerStyle, ...fullWidthStyle }}>
          <h1 className="text-white">BB605 - Shop Small, Love Local</h1>
          <span style={{fontSize: '20px' }}>
            Tues-Fri: 12-6pm, Sat: 10am-6pm, Sun: 12-4pm <br />
          </span>
          <p style={{fontSize: '16px' }}>113 N Splitrock Blvd, Brandon, SD 57005 • 605-582-2033 • support@bb605.shop</p>
        </div>

        {/* Marquee Section */}
        <div style={fullWidthStyle}>
          <Marquee gradient={false} speed={50} loop={0} delay={0} autoFill={true}>
            {vendors.map((vendor, index) => (
              <div key={index} style={{ textAlign: 'center' }}>
                <a href={`/vendor-shop?id=${vendor.id}`}>
                  <img src={vendor.logo_url} alt={`vendor-${index}`} style={marqueeImageStyle} />
                  <p>{vendor.name}</p>
                </a>
              </div>
            ))}
          </Marquee>
        </div>

        {/* Paragraph Section */}
        <div style={{ ...paragraphStyle, ...fullWidthStyle }}>
          <p style={{fontSize: '16px' }}>
            There&apos;s never been a more important time to shop locally! In a world full of choices,
            choose community, sustainability, and the unique artistry of local creators.
          </p>
          <a href="/auth-shop" style={buttonStyle}>Shop Now</a>
        </div>

        {/* Image with Text Section */}
        <div style={vendorSectionStyle}>
          <img alt="local vendors" style={vendorImageStyle} />
          <div style={vendorTextStyle}>
            Over 50+ Local Vendors
            <br />
            <a className="" href="https://bb605.shop/current-vendors" style={buttonStyle}>Current Vendors</a>
          </div>
        </div>
      </div>
      </PageLayout>

    </>
  );
};
